import React, { useState } from 'react';
import { LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Line, ResponsiveContainer } from 'recharts';
import { DashboardCard } from '../components/ui/dashboard-card';
import { AlertCircle, Clock, Target, TrendingUp } from 'lucide-react';
import { AdaptiveLearningEngine } from './adaptiveLearning';
import { TestResult, UserProfile, WrongAnswer } from '../models/User';

interface AdaptiveInsightsProps {
  engine: AdaptiveLearningEngine;
  recentScores: Array<{
    date: string;
    score: number;
  }>;
}

const AdaptiveInsights: React.FC<AdaptiveInsightsProps> = ({ engine, recentScores }) => {
  const [showAllWeakCategories, setShowAllWeakCategories] = useState(false);
  const [showAllCategories, setShowAllCategories] = useState(false);
  
  const insights = engine.generateInsights();
  const categoryStrengths = engine.getCategoryStrengths()
    .filter(category => category.successRate > 0);

  const ITEMS_TO_SHOW = 5;

  const visibleWeakCategories = showAllWeakCategories 
    ? insights.weakCategories 
    : insights.weakCategories.slice(0, ITEMS_TO_SHOW);

  const visibleCategories = showAllCategories 
    ? categoryStrengths 
    : categoryStrengths.slice(0, ITEMS_TO_SHOW);

  return (
    <div className="space-y-6">
      <DashboardCard icon={TrendingUp} title="Performance Overview">
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={recentScores}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis domain={[0, 100]} />
              <Tooltip />
              <Line type="monotone" dataKey="score" stroke="#2563eb" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </DashboardCard>

      <DashboardCard icon={Target} title="Focus Areas (Need Improvement)">
        <div className="space-y-4 transition-all duration-300 ease-in-out">
          {visibleWeakCategories.map((category, index) => (
            <div key={index} className="border-b pb-4 last:border-0">
              <div className="flex justify-between items-center mb-2">
                <h4 className="font-semibold">{category.category}</h4>
                <span className="text-red-500">
                  {category.successRate === 0 
                    ? '0%' 
                    : `${(category.successRate * 100).toFixed(1)}%`}
                </span>
              </div>
              <div className="bg-gray-200 dark:bg-gray-700 h-2 rounded-full">
                <div 
                  className="bg-red-500 h-2 rounded-full transition-all"
                  style={{ width: `${Math.max(category.successRate * 100, 2)}%` }}
                />
              </div>
            </div>
          ))}
          
          {insights.weakCategories.length > ITEMS_TO_SHOW && (
            <button
              onClick={() => setShowAllWeakCategories(!showAllWeakCategories)}
              className="w-full mt-2 text-sm text-blue-500 hover:text-blue-600 dark:text-blue-400 
                       dark:hover:text-blue-300 transition-colors"
            >
              {showAllWeakCategories 
                ? '← Show Less' 
                : `Show ${insights.weakCategories.length - ITEMS_TO_SHOW} More →`}
            </button>
          )}
        </div>
      </DashboardCard>

      {categoryStrengths.length > 0 && (
        <DashboardCard icon={TrendingUp} title="All Categories Performance">
          <div className="space-y-4 transition-all duration-300 ease-in-out">
            {visibleCategories
              .sort((a, b) => b.successRate - a.successRate)
              .map((category, index) => (
                <div key={index} className="border-b pb-4 last:border-0">
                  <div className="flex justify-between items-center mb-2">
                    <h4 className="font-semibold">{category.category}</h4>
                    <div className="flex items-center gap-2">
                      <span className={`${
                        category.successRate >= 0.7 ? 'text-green-500' : 
                        category.successRate >= 0.5 ? 'text-yellow-500' : 'text-red-500'
                      }`}>
                        {(category.successRate * 100).toFixed(1)}%
                      </span>
                      <span className="text-sm text-gray-500">
                        ({category.totalAttempts} attempts)
                      </span>
                    </div>
                  </div>
                  <div className="bg-gray-200 dark:bg-gray-700 h-2 rounded-full">
                    <div 
                      className={`h-2 rounded-full transition-all ${
                        category.successRate >= 0.7 ? 'bg-green-500' : 
                        category.successRate >= 0.5 ? 'bg-yellow-500' : 'bg-red-500'
                      }`}
                      style={{ width: `${category.successRate * 100}%` }}
                    />
                  </div>
                  <div className="mt-2 text-sm text-gray-500">
                    Avg. Time: {Math.round(category.averageTime)}s
                  </div>
                </div>
            ))}
            
            {categoryStrengths.length > ITEMS_TO_SHOW && (
              <button
                onClick={() => setShowAllCategories(!showAllCategories)}
                className="w-full mt-2 text-sm text-blue-500 hover:text-blue-600 dark:text-blue-400 
                         dark:hover:text-blue-300 transition-colors"
              >
                {showAllCategories 
                  ? '← Show Less' 
                  : `Show ${categoryStrengths.length - ITEMS_TO_SHOW} More →`}
              </button>
            )}
          </div>
        </DashboardCard>
      )}

      <DashboardCard icon={Clock} title="Time Management">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-sm text-gray-500 dark:text-gray-400">Average Time per Question</p>
            <p className="text-2xl font-bold">
              {Math.round(insights.timeManagement.averageQuestionTime)}s
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-500 dark:text-gray-400">Target Time</p>
            <p className="text-2xl font-bold">
              {Math.round(insights.timeManagement.recommendedTimePerQuestion)}s
            </p>
          </div>
        </div>
        <div className="mt-4">
          <p className="text-sm bg-blue-50 dark:bg-blue-900 p-3 rounded">
            {insights.timeManagement.timeManagementAdvice}
          </p>
        </div>
      </DashboardCard>

      <DashboardCard icon={AlertCircle} title="Study Recommendations">
        <div className="space-y-3">
          <div className="p-3 bg-green-50 dark:bg-green-900 rounded">
            <p className="font-semibold">Practice Frequency</p>
            <p className="text-sm">{insights.suggestedPracticeFrequency}</p>
          </div>
          <div className="p-3 bg-blue-50 dark:bg-blue-900 rounded">
            <p className="font-semibold">Current Performance</p>
            <p className="text-sm">Your performance is {insights.performanceTrend}</p>
          </div>
        </div>
      </DashboardCard>
    </div>
  );
};

export default AdaptiveInsights;