import { logAnalyticsEvent } from './Analytics';

export const trackError = (
  error: Error,
  context: {
    type: 'js' | 'api' | 'network' | 'other';
    component?: string;
    action?: string;
    metadata?: Record<string, any>;
  }
) => {
  const errorData = {
    message: error.message,
    stack: error.stack,
    timestamp: new Date().toISOString(),
    ...context,
  };

  logAnalyticsEvent(
    context.type === 'api' ? 'api_error' : 'error_occurred',
    errorData
  );
};

export const initializeErrorTracking = () => {
  window.addEventListener('error', (event) => {
    trackError(event.error, {
      type: 'js',
      component: 'window',
      action: 'uncaught_error'
    });
  });

  window.addEventListener('unhandledrejection', (event) => {
    trackError(event.reason, {
      type: 'js',
      component: 'window',
      action: 'unhandled_promise'
    });
  });
}; 