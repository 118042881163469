import React from 'react';
import { Question } from '../models/Quiz';

export interface AnswerOptionsProps {
  currentQuestion: Question;
  selectedAnswer: string | null;
  showResult: boolean;
  handleAnswer: (answer: string) => void;
  isCorrect: (option: string) => boolean;
}

const AnswerOptions: React.FC<AnswerOptionsProps> = ({
  currentQuestion,
  selectedAnswer,
  showResult,
  handleAnswer,
  isCorrect
}) => (
  <>
    {['A', 'B', 'C', 'D', 'E'].map((option) => {
      const optionKey = `option_${option.toLowerCase()}` as keyof Question;
      const optionText = currentQuestion[optionKey];

      if (!optionText || optionText === "") return null;

      return (
        <div key={option} className="option-container flex items-center">
          <span className="option-letter font-bold mr-2">{option}.</span>
          <button
            className={`option-button flex-grow ${selectedAnswer === option ? (isCorrect(option) ? 'correct' : 'incorrect') : ''}`}
            onClick={() => handleAnswer(option)}
            disabled={showResult}
          >
            {optionText}
            {showResult && selectedAnswer === option && (
              <span className="result-icon ml-2">{isCorrect(option) ? '✓' : '✗'}</span>
            )}
          </button>
        </div>
      );
    })}
  </>
);

export default AnswerOptions;