import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Timer from './Timer';
import ProgressBar from './ProgressBar';
import { QuizProgress, TestResult, WrongAnswer } from '../models/User';
import { INITIAL_QUIZ_TIME, PASSING_PERCENTAGE } from '../constants';
import LoadingSpinner from './LoadingSpinner';
import { logAnalyticsEvent, ANALYTICS_EVENTS, trackQuizPerformance, trackError } from '../utils/Analytics';
import { saveQuizProgress, loadQuizProgress, saveTestResult, clearQuizProgress, sanitizeQuestion } from '../utils/quizUtils';
import { fetchQuestions } from '../utils/FirebaseUtils'
import { QuizComponent } from './QuizComponent'
import QuizErrorBoundary from './QuizErrorBoundary';
import { Question } from '@/models/Quiz';

interface QuizContainerProps {
  quizId: number;
  isSubscribed: boolean;
  category?: string;
  initialQuizProgress: QuizProgress;
}

const QuizContainer: React.FC<QuizContainerProps> = ({ 
  quizId, 
  isSubscribed, 
  category,
  initialQuizProgress 
}) => {
  useEffect(() => {
    console.log('QuizContainer mounted with props:', { quizId, isSubscribed, category, initialQuizProgress });
  }, []);

  const navigate = useNavigate();
  const [quizProgress, setQuizProgress] = useState<QuizProgress | null>(() => initialQuizProgress || null);
  const [isLoading, setIsLoading] = useState(!initialQuizProgress);
  const [error, setError] = useState<Error | null>(null);
  const [quizFinished, setQuizFinished] = useState(false);
  const [timeLeft, setTimeLeft] = useState(INITIAL_QUIZ_TIME);
  const [showHint, setShowHint] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [showResult, setShowResult] = useState(false);
  const [currentExplanation, setCurrentExplanation] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const hasTimedOutRef = useRef(false);

  const quizPerformanceTracker = trackQuizPerformance(quizId);

  useEffect(() => {
    logAnalyticsEvent(ANALYTICS_EVENTS.QUIZ_STARTED, {
      quiz_id: quizId,
      quiz_type: isSubscribed ? 'full' : 'sample',
      category
    });

    return () => {
      quizPerformanceTracker.end();
    };
  }, [quizId, isSubscribed, category]);

  useEffect(() => {
    const initializeQuiz = async () => {
      if (quizProgress || isLoading) {
        return;
      }
      
      setIsLoading(true);
      
      try {
        const existingProgress = await loadQuizProgress(quizId, isSubscribed);
        
        if (existingProgress) {
          setQuizProgress(existingProgress);
        } else {
          const questions = await fetchQuestions(isSubscribed, [], category, isSubscribed ? 'full' : 'sample');
          
          if (!questions.length) {
            throw new Error('No questions available');
          }

          const newQuizProgress: QuizProgress = {
            quizId,
            questions,
            currentQuestionIndex: 0,
            score: 0,
            wrongAnswers: [],
            initialQuestionIndex: 0,
            date: new Date().toISOString(),
            questionIds: questions.map(q => q.id),
            type: isSubscribed ? 'full' : 'sample'
          };
          
          await saveQuizProgress(quizId, newQuizProgress);
          setQuizProgress(newQuizProgress);
        }
      } catch (error) {
        console.error('Error initializing quiz:', error);
        setError(new Error('Failed to initialize quiz. Please try again.'));
      } finally {
        setIsLoading(false);
      }
    };

    initializeQuiz();
  }, [quizId, isSubscribed, category]);

  const handleTimeUp = useCallback(() => {
    if (hasTimedOutRef.current) return;
    hasTimedOutRef.current = true;
    handleAnswer(null);
  }, []);

  useEffect(() => {
    if (timeLeft > 0 && !quizFinished) {
      timerRef.current = setTimeout(() => {
        setTimeLeft(prev => prev - 1);
      }, 1000);
    } else if (timeLeft === 0 && !quizFinished) {
      handleTimeUp();
    }

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [timeLeft, quizFinished, handleTimeUp]);

  const handleAnswer = useCallback((answer: string | null) => {
    if (!quizProgress) return;

    const currentQuestion = quizProgress.questions[quizProgress.currentQuestionIndex];
    const isCorrect = answer === currentQuestion.correct_answer;
    
    const newScore = isCorrect ? quizProgress.score + 1 : quizProgress.score;
    const updatedQuestions = quizProgress.questions.map((q, index) => 
      index === quizProgress.currentQuestionIndex ? {...q, selected_answer: answer} : q
    );

    const updatedProgress = {
      ...quizProgress,
      score: newScore,
      questions: updatedQuestions,
    };

    if (!isCorrect) {
      updatedProgress.wrongAnswers = [
        ...updatedProgress.wrongAnswers,
        {
          question: sanitizeQuestion({...currentQuestion, selected_answer: answer}),
          explanation: currentQuestion.explanation,
          note: currentExplanation,
          date: new Date().toISOString()
        }
      ];
    }

    setQuizProgress(updatedProgress);
    saveQuizProgress(quizId, updatedProgress);
    setSelectedAnswer(answer);
    setShowResult(true);

    logAnalyticsEvent(ANALYTICS_EVENTS.QUESTION_ANSWERED, {
      questionId: currentQuestion.id,
      isCorrect: isCorrect,
      timeSpent: INITIAL_QUIZ_TIME - timeLeft,
      attemptCount: 1,
      hintUsed: showHint
    });
  }, [quizProgress, quizId, currentExplanation]);

  const handleQuizFinish = useCallback(async () => {
    if (!quizProgress) return;

    setQuizFinished(true);

    const testResult: TestResult = {
      id: Date.now(),
      date: new Date().toISOString(),
      score: quizProgress.score,
      totalQuestions: quizProgress.questions.length,
      percentage: (quizProgress.score / quizProgress.questions.length) * 100,
      questions: quizProgress.questions,
      wrongAnswers: quizProgress.wrongAnswers
    };

    await saveTestResult(testResult);
    await clearQuizProgress(quizId);

    logAnalyticsEvent(ANALYTICS_EVENTS.QUIZ_COMPLETED, {
      quiz_id: quizProgress.quizId,
      score: quizProgress.score,
      totalQuestions: quizProgress.questions.length,
      percentage: testResult.percentage,
      timeSpent: INITIAL_QUIZ_TIME - timeLeft,
      questionIds: quizProgress.questions.map(q => q.id)
    });

    window.dispatchEvent(new Event('quizCompleted'));
    window.dispatchEvent(new Event('storage'));
  }, [quizProgress, quizId, timeLeft]);

  const findNextValidQuestionIndex = (startIndex: number): number => {
    for (let i = startIndex; i < (quizProgress?.questions.length ?? 0); i++) {
      if (quizProgress?.questions[i] && Object.keys(quizProgress.questions[i]).length > 0) {
        return i;
      }
    }
    return -1;
  };

  const nextQuestion = useCallback(async () => {
    if (!quizProgress) return;
    
    const questionStartTime = performance.now();
    
    try {
      const nextValidIndex = findNextValidQuestionIndex(quizProgress.currentQuestionIndex + 1);
      
      if (nextValidIndex >= 0) {
        const updatedProgress = {
          ...quizProgress,
          currentQuestionIndex: nextValidIndex,
          questions: quizProgress.questions.filter(q => q && Object.keys(q).length > 0),
          questionIds: quizProgress.questionIds.filter((_, index) => 
            quizProgress.questions[index] && Object.keys(quizProgress.questions[index]).length > 0
          )
        };
        
        // Save first to ensure persistence
        await saveQuizProgress(quizId, updatedProgress);
        // Then update local state
        setQuizProgress(updatedProgress);
        
        // Reset other states after confirming save
        setTimeLeft(INITIAL_QUIZ_TIME);
        hasTimedOutRef.current = false;
        setSelectedAnswer(null);
        setShowResult(false);
        setShowHint(false);
        setCurrentExplanation('');
      } else {
        handleQuizFinish();
      }

      logAnalyticsEvent(ANALYTICS_EVENTS.QUESTION_ANSWERED, {
        questionId: getCurrentQuestion()?.id ?? 0,
        isCorrect: true,
        timeSpent: performance.now() - questionStartTime,
        attemptCount: 1,
        hintUsed: showHint
      });
    } catch (error) {
      handleError(error as Error);
    }
  }, [quizProgress, quizId, handleQuizFinish]);

  const getCurrentQuestion = useCallback((): Question | null => {
    if (!quizProgress?.questions?.length) {
      return null;
    }
    
    const currentQuestion = quizProgress.questions[quizProgress.currentQuestionIndex];
    return currentQuestion && Object.keys(currentQuestion).length > 0 ? currentQuestion : null;
  }, [quizProgress?.currentQuestionIndex, quizProgress?.questions]);

  const calculateProgress = () => {
    if (!quizProgress) return 0;
    return (quizProgress.currentQuestionIndex / quizProgress.questions.length) * 100;
  };

  const handleExplanationChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentExplanation(e.target.value);
  };

  const handleSaveAndContinue = () => {
    if (currentExplanation === '') {
      setShowConfirmation(true);
    } else {
      saveExplanation();
    }
  };

  const saveExplanation = () => {
    if (!quizProgress) return;
    const currentQuestion = quizProgress.questions[quizProgress.currentQuestionIndex];
    const newWrongAnswer: WrongAnswer = {
      question: currentQuestion,
      explanation: currentQuestion.explanation,
      note: currentExplanation,
      date: new Date().toISOString()
    };
    const updatedProgress = {
      ...quizProgress,
      wrongAnswers: [...quizProgress.wrongAnswers, newWrongAnswer]
    };
    setQuizProgress(updatedProgress);
    saveQuizProgress(quizId, updatedProgress);
    setCurrentExplanation('');
    nextQuestion();
  };

  const confirmContinueWithoutExplanation = () => {
    setShowConfirmation(false);
    nextQuestion();
  };

  const handleError = (error: Error) => {
    trackError(error, {
      component: 'QuizContainer',
      quizId,
      questionId: getCurrentQuestion()?.id
    });
    setError(error);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div className="error-container">
        <h2>Error Loading Quiz</h2>
        <p>{error.message}</p>
        <button onClick={() => window.location.reload()}>Try Again</button>
      </div>
    );
  }

  if (!quizProgress) {
    return <LoadingSpinner />;
  }

  if (quizFinished) {
    const percentage = (quizProgress?.score ?? 0) / (quizProgress?.questions.length ?? 1) * 100;
    const passed = percentage >= PASSING_PERCENTAGE;

    return (
        <div className="quiz-completion-container">
          <h2 className="text-2xl font-bold mb-4">Quiz Completed!</h2>
          {/* <p className="text-lg mb-2">Your score: {score} out of {totalQuestions}</p> */}
          <p className="text-lg mb-4">Percentage: {percentage.toFixed(2)}%</p>
          <h3 className={`text-xl font-semibold mb-2 ${passed ? 'text-green-600' : 'text-red-600'}`}>
            {passed ? "Congratulations! You passed!" : "Sorry, you didn't pass."}
          </h3>
          {!passed && <p className="mb-6 text-gray-600">Keep studying and try again!</p>}

          <button 
            className="w-full bg-blue-500 text-white px-6 py-3 rounded hover:bg-blue-600 transition-colors duration-200 mb-4"
            onClick={() => navigate('/dashboard')}
          >
            Save Results and Go to Your Dashboard
          </button>
          <button 
            className="w-full bg-green-500 text-white px-6 py-3 rounded hover:bg-green-600 transition-colors duration-200"
            onClick={() => navigate('/feedback')}
          >
            Give Us Feedback
          </button>
        </div>
    );
  }

  return (
    <QuizErrorBoundary>
      <div className="quiz-container bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        <div className="quiz-content p-6 space-y-6">
          <div className="flex items-center justify-between">
            <Timer timeLeft={timeLeft} />
            <div className="w-2/3">
              <ProgressBar progress={calculateProgress()} />
            </div>
          </div>
          <hr className="mb-4 border-t border-gray-300 dark:border-gray-600" />
          <p className="text-sm text-gray-600 dark:text-gray-400 mb-2">
            {category ? `${category} quiz` : 
             quizProgress?.questions ? 
               `${quizProgress.questions.length}-question ${quizProgress.type} quiz` :
               'Loading quiz...'}
          </p>
          {getCurrentQuestion() ? (
            <QuizComponent
              quizProgress={quizProgress}
              handleAnswer={handleAnswer}
              nextQuestion={nextQuestion}
              showHint={showHint}
              setShowHint={setShowHint}
              selectedAnswer={selectedAnswer}
              showResult={showResult}
              currentExplanation={currentExplanation}
              handleExplanationChange={handleExplanationChange}
              handleSaveAndContinue={handleSaveAndContinue}
              isSubscriber={isSubscribed}
            />
          ) : (
            <div className="p-4">
              <LoadingSpinner />
            </div>
          )}
          {showConfirmation && (
            <div className="confirmation-modal fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <div className="confirmation-modal-content bg-white dark:bg-gray-700 p-6 rounded-lg shadow-xl">
                <p className="text-gray-800 dark:text-white mb-4">You have not provided an explanation. Are you sure you want to continue?</p>
                <div className="confirmation-modal-buttons flex justify-end space-x-4">
                  <button 
                    onClick={confirmContinueWithoutExplanation}
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors duration-200"
                  >
                    Yes, continue
                  </button>
                  <button 
                    onClick={() => setShowConfirmation(false)}
                    className="bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400 transition-colors duration-200"
                  >
                    No, go back
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </QuizErrorBoundary>
  );
};

export default QuizContainer;
