import React from 'react';
import { ClockIcon } from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';

const Timer = ({ timeLeft }) => (
  <div className="flex items-center space-x-1 min-w-[80px]">
    <ClockIcon className="h-5 w-5 text-gray-600" />
    <span className="text-sm font-bold">
      {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}
    </span>
  </div>
);

Timer.propTypes = {
  timeLeft: PropTypes.number.isRequired
};

export default Timer;