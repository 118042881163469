import React, { useState } from 'react';
import { WrongAnswer } from '../../models/User';
import { Edit2, Check } from 'lucide-react';
import { logAnalyticsEvent, ANALYTICS_EVENTS } from '../../utils/Analytics';

interface FlashCardsProps {
    wrongAnswers: WrongAnswer[];
    onUpdateExplanation?: (questionId: number, explanation: string) => void;
    onMarkComplete?: (questionId: number) => void;
}

const FlashCard: React.FC<FlashCardsProps> = ({
    wrongAnswers,
    onUpdateExplanation,
    onMarkComplete
}) => {
    const [isFlipped, setIsFlipped] = useState<number | null>(null);
    const [isEditing, setIsEditing] = useState<number | null>(null);
    const [explanation, setExplanation] = useState<string>('');
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE = 6; // 3x3 grid
    const [showConfirmDialog, setShowConfirmDialog] = useState<number | null>(null);

    // Filter wrong answers by category
    const filteredAnswers = selectedCategory
        ? wrongAnswers.filter(wa => wa.question.category === selectedCategory)
        : wrongAnswers;

    // Paginate the filtered answers
    const paginatedAnswers = filteredAnswers.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    const totalPages = Math.ceil(filteredAnswers.length / ITEMS_PER_PAGE);
    const categories = Array.from(new Set(wrongAnswers.map(wa => wa.question.category))).sort();

    const handleFlip = (id: number) => {
        setIsFlipped(isFlipped === id ? null : id);
        logAnalyticsEvent(ANALYTICS_EVENTS.FLASHCARD_FLIPPED, {
            questionId: id,
            category: paginatedAnswers.find(card => card.question.id === id)?.question.category
        });
    };

    const handleEdit = (e: React.MouseEvent, id: number, currentExplanation: string) => {
        e.stopPropagation();
        setIsEditing(id);
        setExplanation(currentExplanation || '');
        setIsFlipped(null);
        logAnalyticsEvent(ANALYTICS_EVENTS.FLASHCARD_EDITED, {
            questionId: id
        });
    };

    const handleSave = async (questionId: number) => {
        if (onUpdateExplanation) {
            await onUpdateExplanation(questionId, explanation);
        }
        setIsEditing(null);
    };

    const handleMarkReviewed = (e: React.MouseEvent, questionId: number) => {
        e.stopPropagation();
        if (onMarkComplete) {
            onMarkComplete(questionId);
        }
    };

    return (
        <div className="col-span-full">
            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4 mb-4">
                <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                    Flashcards ({filteredAnswers.length})
                </h2>
                <select
                    value={selectedCategory}
                    onChange={(e) => {
                        setSelectedCategory(e.target.value);
                        setCurrentPage(1);
                    }}
                    className="p-2 border rounded dark:bg-gray-700 dark:text-gray-200 w-full sm:w-auto"
                >
                    <option value="">All Categories</option>
                    {categories.map(category => (
                        <option key={category} value={category}>{category}</option>
                    ))}
                </select>
            </div>

            {paginatedAnswers.length > 0 ? (
            <>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {paginatedAnswers.map((card) => (
                        <div
                            key={card.question.id}
                            onClick={() => handleFlip(card.question.id)}
                            className="bg-white dark:bg-gray-700 rounded-lg shadow p-4 cursor-pointer 
                                        transition-all duration-300 ease-in-out transform hover:scale-105"
                        >
                            {isFlipped === card.question.id ? (
                                <div>
                                    <p className="text-gray-800 dark:text-gray-200 font-semibold mb-2">Answer:</p>
                                    <p className="text-gray-600 dark:text-gray-300">{card.question.correct_answer}</p>

                                    <p className="text-gray-800 dark:text-gray-200 font-semibold mt-4 mb-2">Explanation:</p>
                                    <p className="text-gray-600 dark:text-gray-300">{card.explanation}</p>
                                </div>
                            ) : (
                                <div>
                                    <p className="text-gray-800 dark:text-gray-200 font-semibold mb-2">Question:</p>
                                    <p className="text-gray-600 dark:text-gray-300 mb-4">{card.question.question_text}</p>

                                    {card.attempts && card.attempts > 1 && (
                                        <span className="text-sm text-red-500 dark:text-red-400">
                                            Missed {card.attempts} times
                                        </span>
                                    )}

                                    {isEditing === card.question.id ? (
                                        <div onClick={(e) => e.stopPropagation()}>
                                            <p className="text-gray-800 dark:text-gray-200 font-semibold mt-4 mb-2">Your Notes:</p>
                                            <textarea
                                                value={explanation}
                                                onChange={(e) => setExplanation(e.target.value)}
                                                className="w-full p-2 border rounded dark:bg-gray-600 dark:text-gray-200"
                                            />
                                            <button
                                                onClick={() => handleSave(card.question.id)}
                                                className="mt-2 bg-blue-500 text-white px-2 py-1 rounded"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    ) : card.note && (
                                        <>
                                            <p className="text-gray-800 dark:text-gray-200 font-semibold mt-4 mb-2">Your Notes:</p>
                                            <p className="text-gray-600 dark:text-gray-300 italic">
                                                {card.note}
                                            </p>
                                        </>
                                    )}
                                </div>
                            )}
                            <div className="mt-4 flex justify-between">
                                <button
                                    onClick={(e) => handleMarkReviewed(e, card.question.id)}
                                    className="p-2 rounded bg-gray-300 dark:bg-gray-600 hover:bg-gray-400 dark:hover:bg-gray-500"
                                >
                                    <Check size={16} />
                                </button>
                                {isFlipped !== card.question.id && (
                                    <button
                                        onClick={(e) => handleEdit(e, card.question.id, card.explanation || '')}
                                        className="p-2 rounded bg-blue-500 hover:bg-blue-600"
                                    >
                                        <Edit2 size={16} color="white" />
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>

                {/* Pagination controls */}
                {totalPages > 1 && (
                    <div className="flex justify-center mt-4 gap-2">
                        <button
                            onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                            disabled={currentPage === 1}
                            className="px-3 py-1 rounded bg-blue-500 text-white disabled:opacity-50"
                        >
                            Previous
                        </button>
                        <span className="px-3 py-1">
                            Page {currentPage} of {totalPages}
                        </span>
                        <button
                            onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
                            disabled={currentPage === totalPages}
                            className="px-3 py-1 rounded bg-blue-500 text-white disabled:opacity-50"
                        >
                            Next
                        </button>
                    </div>
                )}
            </>
            ) : (
                <p className="text-center text-gray-600 dark:text-gray-400">
                    No flashcards available {selectedCategory && `in ${selectedCategory}`}
                </p>
            )}
        </div>
    );
};

export default FlashCard; 