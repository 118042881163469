import React from 'react';
import AdaptiveQuiz from '../components/AdaptiveQuiz';

const TestAdaptiveQuiz: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="container mx-auto py-8">
        <h1 className="text-3xl font-bold mb-8 px-4">Adaptive Quiz Practice</h1>
        <AdaptiveQuiz 
          quizId={1}
          isSubscribed={true}
        />
      </div>
    </div>
  );
};

export default TestAdaptiveQuiz; 