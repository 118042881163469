import { Question } from './Quiz'

export interface User {
    id: string
    email: string
    profile: UserProfile | null;
    quizProgress: QuizProgress | null
    testResults: TestResult[]
    subscriptionStatus: string | ''
    adaptiveLearning?: AdaptiveLearningProfile;
}

export interface UserProfile {
  name: string;
  examDate: string;
  studyGoal: string;
  educationLevel?: EducationLevel;
  preferredStudyTime?: PreferredStudyTime;
  challengingTopics?: ChallengingTopic[];
  learningStyle?: LearningStyle;
  onboardingCompleted: boolean;
}

export interface QuizProgress {
    quizId: number;
    currentQuestionIndex: number;
    initialQuestionIndex: number;
    score: number;
    date: string;
    wrongAnswers: WrongAnswer[];
    questions: Question[];
    questionIds: number[];
    type?: 'sample' | 'full' | 'category'; // Optional for backward compatibility
    category?: string; // Optional for category-specific quizzes
}

// TODO: Implement analytics for test results
export interface TestResult {
    id: number;
    date: string;
    score: number;
    totalQuestions: number;
    percentage: number;
    questions: Question[];
    wrongAnswers: WrongAnswer[];
    timeTaken?: number; // in seconds
    adaptiveMetrics?: {
      startingDifficulty: number;
      endingDifficulty: number;
      averageResponseTime: number;
      categoryPerformance: {
        [category: string]: {
          correct: number;
          total: number;
          averageTime: number;
        };
      };
    };
}

export interface WrongAnswer {
    question: Question;
    explanation: string;
    note?: string; 
    date: string;
    isReviewed?: boolean;
    attempts?: number; // New field to track number of times missed
}

export enum StudyGoal {
    PASS_EXAM = "Pass the LCSW exam",
    IMPROVE_SKILLS = "Improve clinical skills",
    CAREER_ADVANCEMENT = "Career advancement"
}

export enum EducationLevel {
    BACHELORS = "Bachelor's in Social Work",
    MASTERS = "Master's in Social Work",
    OTHER = "Other related degree"
}

export enum WorkExperience {
    LESS_THAN_2 = "Less than 2 years",
    TWO_TO_5 = "2-5 years",
    FIVE_TO_10 = "5-10 years",
    MORE_THAN_10 = "More than 10 years"
}

export enum PreferredStudyTime {
    MORNING = "Morning",
    AFTERNOON = "Afternoon",
    EVENING = "Evening",
    NIGHT = "Night"
}

export enum ChallengingTopic {
    ETHICS = "Ethics",
    DIAGNOSIS = "Diagnosis",
    TREATMENT_PLANNING = "Treatment Planning",
    HUMAN_DEVELOPMENT = "Human Development",
    PSYCHOTHERAPY = "Psychotherapy",
    DIVERSITY = "Diversity and Cultural Competence",
    RESEARCH = "Research and Program Evaluation"
}

export enum LearningStyle {
    VISUAL = "Visual",
    AUDITORY = "Auditory",
    READING_WRITING = "Reading/Writing",
    KINESTHETIC = "Kinesthetic"
}

// Add this at the top of the file
export type ImprovementStatus = 'improving' | 'steady' | 'declining';

// New interfaces for adaptive learning
export interface AdaptiveLearningProfile {
  // Current state
  currentState: {
    difficulty: number;
    consecutiveCorrect: number;
    consecutiveIncorrect: number;
    recentlyAskedQuestions: number[];
  };
  
  // Performance tracking
  categoryStrengths: {
    [category: string]: {
      correctCount: number;
      totalCount: number;
      averageTime: number;
      lastAttempted: string;
      improvement: ImprovementStatus; // Use the type alias
      successRate: number;
      difficultyLevel: number;
    }
  };
  
  // Learning patterns
  learningPatterns: {
    averageQuestionTime: number;
    optimalDifficulty: number;
    lastUpdate: string;
  };
  
  // Study planning
  studyPlan: {
    weeklyHours: number;
    recommendedPace: number;
    categoryPlans: Array<{
      category: string;
      hoursPerWeek: number;
      focusAreas: string[];
      recommendedResources: string[];
    }>;
    milestones: Array<{
      date: string;
      targetScore: number;
      categoriesComplete: string[];
      recommendedActions: string[];
    }>;
  };
  
  // Insights and recommendations
  insights: {
    weakCategories: string[];
    conceptsToReview: string[];
    recommendedDifficulty: number;
    suggestedPracticeFrequency: string;
    performanceTrend: 'improving' | 'steady' | 'declining';
    timeManagement: {
      averageQuestionTime: number;
      recommendedTimePerQuestion: number;
      timeManagementAdvice: string;
    };
    lastUpdate: string;
  };
  
  // Quick access metrics
  quickMetrics: {
    // Overall performance
    overallSuccessRate: number;
    totalQuestionsAttempted: number;
    averageQuestionTime: number;
    currentStreak: number;
    longestStreak: number;
    
    // Time-based analytics
    lastSessionDate: string;
    weeklyActivityStreak: number;
    studyTimeThisWeek: number;
    
    // Progress indicators
    estimatedExamReadiness: number; // 0-100%
    completedCategories: string[];
    mastery: {
      beginner: string[];      // Categories < 50%
      intermediate: string[];  // Categories 50-80%
      advanced: string[];      // Categories > 80%
    };
    
    // Recent activity
    recentPerformance: Array<{
      date: string;
      score: number;
      category: string;
      questionsAttempted: number;
    }>;
    
    // Study habits
    preferredTimes: {
      dayOfWeek: string;
      timeOfDay: string;
      averagePerformance: number;
    }[];
    
    // Adaptive behavior
    difficultyProgression: Array<{
      date: string;
      difficulty: number;
      successRate: number;
    }>;
  };
  
  // Session tracking
  currentSession?: {
    startTime: string;
    questionsAnswered: number;
    correctAnswers: number;
    averageResponseTime: number;
    categoryFocus: string[];
    initialDifficulty: number;
    currentDifficulty: number;
  };
}

// Convert interface to class for User
export class User {
    id: string;
    email: string;
    profile: UserProfile | null;
    quizProgress: QuizProgress | null;
    testResults: TestResult[];
    subscriptionStatus: string;
    adaptiveLearning?: AdaptiveLearningProfile;

    constructor(data: {
        id: string;
        email: string;
        profile: UserProfile | null;
        quizProgress: QuizProgress | null;
        testResults: TestResult[];
        subscriptionStatus: string;
        adaptiveLearning?: AdaptiveLearningProfile;
    }) {
        this.id = data.id;
        this.email = data.email;
        this.profile = data.profile;
        this.quizProgress = data.quizProgress;
        this.testResults = data.testResults;
        this.subscriptionStatus = data.subscriptionStatus;
        this.adaptiveLearning = data.adaptiveLearning;
    }

    // Getter for subscription status
    get isSubscribed(): boolean {
        return this.subscriptionStatus === "active";
    }

    // Optional: Add other useful getters
    get latestTestResult(): TestResult | null {
        return this.testResults?.length ? this.testResults[this.testResults.length - 1] : null;
    }

    get hasCompletedOnboarding(): boolean {
        return !!this.profile?.onboardingCompleted;
    }

    // New getter for total wrong answers
    get totalWrongAnswers(): WrongAnswer[] {
        if (!this.testResults?.length) return [];

        // Create a Map using question_text as the key to handle duplicates
        const wrongAnswersMap = new Map<string, WrongAnswer>();

        // Iterate through test results in reverse chronological order
        [...this.testResults]
            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
            .forEach(test => {
                test.wrongAnswers.forEach(wrongAnswer => {
                    const key = wrongAnswer.question.question_text;
                    
                    // Only add if not already present (keeps most recent attempt)
                    if (!wrongAnswersMap.has(key)) {
                        wrongAnswersMap.set(key, {
                            ...wrongAnswer,
                            // Add metadata about attempts
                            attempts: this.getQuestionAttempts(wrongAnswer.question.question_text)
                        });
                    }
                });
            });

        return Array.from(wrongAnswersMap.values());
    }

    // Helper method to count attempts for a specific question
    private getQuestionAttempts(questionText: string): number {
        return this.testResults.reduce((count, test) => {
            return count + test.wrongAnswers.filter(
                wa => wa.question.question_text === questionText
            ).length;
        }, 0);
    }
}