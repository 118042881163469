import { LearningStyle, TestResult, UserProfile, WrongAnswer } from '../models/User';
import { Question } from '../models/Quiz';

// Weights for different factors in the algorithm
const WEIGHTS = {
  WRONG_ANSWER_HISTORY: 0.35,    // Higher weight for previously missed questions
  CATEGORY_WEAKNESS: 0.25,       // Weight for weak categories
  TIME_DECAY: 0.15,             // Weight for time since last attempt
  LEARNING_STYLE_MATCH: 0.15,    // Weight for questions matching learning style
  DIFFICULTY_ADJUSTMENT: 0.10    // Weight for progressive difficulty
};

interface CategoryPerformance {
  category: string;
  correctCount: number;
  totalCount: number;
  averageTime: number;
  lastAttempted: Date;
}

interface AdaptiveState {
  currentDifficulty: number;     // 1-10 scale
  categoryStrengths: Map<string, CategoryPerformance>;
  recentlyAskedQuestions: Set<number>;
  consecutiveCorrect: number;
  consecutiveIncorrect: number;
}

/**
 * Scoring weights for question priority calculation
 * These can be tuned based on empirical performance data
 */
const PRIORITY_WEIGHTS = {
  RECENCY: {
    LAST_24H: 0.2,    // Reduce priority if seen very recently
    LAST_WEEK: 0.6,   // Optimal review time for most questions
    LAST_MONTH: 1.0,  // High priority for spaced repetition
    OLDER: 0.8        // Slightly lower priority for very old questions
  },
  PERFORMANCE: {
    WRONG_MULTIPLE_TIMES: 1.0,   // Highest priority for repeatedly missed
    WRONG_ONCE: 0.8,            // High priority for questions missed once
    CORRECT_WITH_DIFFICULTY: 0.6,// Medium priority for struggled questions
    CORRECT_QUICKLY: 0.3        // Lower priority for well-understood questions
  }
};

/**
 * Helper class for managing time-based calculations and intervals
 */
class TimeUtils {
  static getDaysBetween(date1: Date, date2: Date): number {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    return Math.round(Math.abs((date1.getTime() - date2.getTime()) / oneDay));
  }

  static getExpectedTime(difficulty: number): number {
    // Base time in seconds, adjusted by difficulty
    const baseTime = 60; // 1 minute base time
    return baseTime * (1 + (difficulty - 1) * 0.2); // 20% increase per difficulty level
  }
}

/**
 * Helper class for analyzing question attempts and patterns
 */
class AttemptAnalyzer {
  static getConsecutiveCorrect(attempts: QuestionAttempt[]): number {
    let count = 0;
    for (let i = attempts.length - 1; i >= 0; i--) {
      if (attempts[i].isCorrect) count++;
      else break;
    }
    return count;
  }

  static getSuccessRate(attempts: QuestionAttempt[]): number {
    if (attempts.length === 0) return 0;
    const correct = attempts.filter(a => a.isCorrect).length;
    return correct / attempts.length;
  }

  static getAverageTime(attempts: QuestionAttempt[]): number {
    if (attempts.length === 0) return 0;
    const totalTime = attempts.reduce((sum, a) => sum + a.timeSpent, 0);
    return totalTime / attempts.length;
  }
}

interface QuestionAttempt {
  date: Date;
  isCorrect: boolean;
  timeSpent: number; // in seconds
  difficulty: number;
}

interface UserInsights {
  weakCategories: Array<{
    category: string;
    successRate: number;
    recommendedFocus: string[];
  }>;
  recommendedDifficulty: number;
  suggestedPracticeFrequency: string;
  performanceTrend: 'improving' | 'steady' | 'declining';
  timeManagement: {
    averageQuestionTime: number;
    recommendedTimePerQuestion: number;
    timeManagementAdvice: string;
  };
}

export class AdaptiveLearningEngine {
  private userProfile: UserProfile;
  private testHistory: TestResult[];
  private wrongAnswers: WrongAnswer[];
  private adaptiveState: AdaptiveState;

  constructor(
    userProfile: UserProfile,
    testHistory: TestResult[],
    wrongAnswers: WrongAnswer[]
  ) {
    this.userProfile = userProfile;
    this.testHistory = testHistory;
    this.wrongAnswers = wrongAnswers;
    this.adaptiveState = this.initializeAdaptiveState();
  }

  /**
   * Initializes the adaptive state based on user's historical performance
   * Analyzes past tests and wrong answers to set initial difficulty and category strengths
   */
  private initializeAdaptiveState(): AdaptiveState {
    // ... implementation details ...
    return {
      currentDifficulty: 5, // Start at medium difficulty
      categoryStrengths: new Map(),
      recentlyAskedQuestions: new Set(),
      consecutiveCorrect: 0,
      consecutiveIncorrect: 0
    };
  }

  /**
   * Calculates the priority score for a question using a weighted multi-factor approach
   */
  private calculateQuestionPriority(question: Question): number {
    const categoryScore = this.calculateCategoryWeight(question.category);
    const timeDecayScore = this.calculateTimeDecayWeight(question.id);
    const difficultyScore = this.calculateDifficultyMatch(this.estimateQuestionDifficulty(question.id));
    const learningStyleScore = this.calculateLearningStyleMatch();
    const wrongAnswerScore = this.calculateWrongAnswerWeight(question.id);

    return (
      categoryScore * WEIGHTS.CATEGORY_WEAKNESS +
      timeDecayScore * WEIGHTS.TIME_DECAY +
      difficultyScore * WEIGHTS.DIFFICULTY_ADJUSTMENT +
      learningStyleScore * WEIGHTS.LEARNING_STYLE_MATCH +
      wrongAnswerScore * WEIGHTS.WRONG_ANSWER_HISTORY
    );
  }

  /**
   * Calculates weight based on user's performance in the question's category
   * Returns higher values for categories where user struggles
   */
  private calculateCategoryWeight(category: string): number {
    const performance = this.adaptiveState.categoryStrengths.get(category);
    if (!performance) return 1.0; // Default high priority for untested categories

    const successRate = performance.correctCount / performance.totalCount;
    // Inverse relationship: lower success rate = higher priority
    return 1 - (successRate * 0.8); // Keep minimum priority at 0.2
  }

  /**
   * Calculates the time decay weight for spaced repetition
   */
  private calculateTimeDecayWeight(questionId: number): number {
    const lastAttempt = this.getLastAttemptDate(questionId);
    if (!lastAttempt) return 1.0; // Never attempted = high priority

    const daysSinceAttempt = TimeUtils.getDaysBetween(lastAttempt, new Date());
    
    // Modified SuperMemo-2 interval calculation
    const interval = this.getOptimalInterval(questionId);
    const normalizedDays = daysSinceAttempt / interval;

    // Bell curve distribution centered around optimal interval
    return Math.exp(-Math.pow(normalizedDays - 1, 2));
  }

  /**
   * Gets the date of the last attempt for a specific question
   */
  private getLastAttemptDate(questionId: number): Date | null {
    const attempts = this.getQuestionAttempts(questionId);
    if (attempts.length === 0) return null;
    return attempts[attempts.length - 1].date;
  }

  /**
   * Calculates optimal review interval based on previous performance
   * Uses exponential backoff for correctly answered questions
   */
  private getOptimalInterval(questionId: number): number {
    const attempts = this.getQuestionAttempts(questionId);
    const baseInterval = 2; // Base interval in days
    
    if (attempts.length === 0) return baseInterval;

    const consecutiveCorrect = AttemptAnalyzer.getConsecutiveCorrect(attempts);
    // Exponential increase in interval for each correct answer
    return baseInterval * Math.pow(2, consecutiveCorrect);
  }

  /**
   * Matches question difficulty to user's current level
   * Prefers questions slightly above current difficulty
   */
  private calculateDifficultyMatch(questionDifficulty: number): number {
    const diffDelta = questionDifficulty - this.adaptiveState.currentDifficulty;
    
    // Prefer questions 1-2 levels above current difficulty
    if (diffDelta > 0 && diffDelta <= 2) return 1.0;
    // Gradually reduce priority for questions too easy or too hard
    return Math.exp(-Math.abs(diffDelta - 1) / 2);
  }

  /**
   * Updates difficulty based on user performance
   */
  private updateDifficulty(isCorrect: boolean, timeSpent: number): void {
    const expectedTime = TimeUtils.getExpectedTime(this.adaptiveState.currentDifficulty);
    
    if (isCorrect) {
      this.adaptiveState.consecutiveCorrect++;
      this.adaptiveState.consecutiveIncorrect = 0;
      
      // Increase difficulty if answering correctly and quickly
      if (timeSpent < expectedTime && this.adaptiveState.consecutiveCorrect >= 3) {
        this.adaptiveState.currentDifficulty = Math.min(10, this.adaptiveState.currentDifficulty + 1);
        this.adaptiveState.consecutiveCorrect = 0; // Reset after difficulty change
      }
    } else {
      this.adaptiveState.consecutiveIncorrect++;
      this.adaptiveState.consecutiveCorrect = 0;
      
      // Decrease difficulty if struggling
      if (this.adaptiveState.consecutiveIncorrect >= 2) {
        this.adaptiveState.currentDifficulty = Math.max(1, this.adaptiveState.currentDifficulty - 1);
        this.adaptiveState.consecutiveIncorrect = 0; // Reset after difficulty change
      }
    }
  }

  /**
   * Generates personalized study plan based on user's exam date and performance
   */
  private generateStudyPlan(): StudyPlan {
    const examDate = new Date(this.userProfile.examDate);
    const today = new Date();
    const daysUntilExam = TimeUtils.getDaysBetween(today, examDate);

    // Calculate study intensity based on days remaining
    const weeklyHours = this.calculateWeeklyStudyHours(daysUntilExam);
    
    // Distribute hours across categories based on performance
    const categoryPlans = Array.from(this.adaptiveState.categoryStrengths.entries())
      .map(([category, performance]) => {
        const successRate = performance.correctCount / performance.totalCount;
        const hoursNeeded = Math.ceil(weeklyHours * (1 - successRate));
        
        return {
          category,
          hoursPerWeek: hoursNeeded,
          focusAreas: this.identifyFocusAreas(category),
          recommendedResources: this.getRecommendedResources(category)
        };
      });

    return {
      weeklyHours,
      categoryPlans,
      recommendedPace: this.calculateRecommendedPace(daysUntilExam),
      milestones: this.generateMilestones(daysUntilExam)
    };
  }

  /**
   * Analyzes user performance trends and provides recommendations for:
   * - Areas needing more focus
   * - Suggested practice frequency
   * - Question difficulty adjustments
   */
  public generateInsights(): UserInsights {
    const categoryPerformance = Array.from(this.adaptiveState.categoryStrengths.entries());
    const weakCategories = categoryPerformance
      .filter(([_, perf]) => (perf.correctCount / perf.totalCount) < 0.7)
      .map(([category, perf]) => ({
        category,
        successRate: perf.correctCount / perf.totalCount,
        recommendedFocus: this.identifyFocusAreas(category)
      }));

    const recentTests = this.testHistory.slice(-3);
    const performanceTrend = this.calculatePerformanceTrend(recentTests);

    return {
      weakCategories,
      recommendedDifficulty: this.adaptiveState.currentDifficulty,
      suggestedPracticeFrequency: this.calculatePracticeFrequency(),
      performanceTrend,
      timeManagement: {
        averageQuestionTime: this.calculateAverageQuestionTime(),
        recommendedTimePerQuestion: 90, // ASWB exam: 170 questions in 4 hours
        timeManagementAdvice: this.generateTimeManagementAdvice()
      }
    };
  }

  /**
   * Retrieves and analyzes previous attempts for a specific question
   * Currently tracks correctness and time spent
   * TODO: Add difficulty tracking when question difficulty is implemented
   */
  private getQuestionAttempts(questionId: number): QuestionAttempt[] {
    const attempts: QuestionAttempt[] = [];
    
    // Check test history
    this.testHistory.forEach(test => {
      const question = test.questions.find(q => q.id === questionId);
      if (question) {
        const isCorrect = !test.wrongAnswers.some(wa => wa.question.id === questionId);
        attempts.push({
          date: new Date(test.date),
          isCorrect,
          timeSpent: test.timeTaken || 0,
          difficulty: this.estimateQuestionDifficulty(questionId) // Estimate difficulty based on user performance
        });
      }
    });

    return attempts.sort((a, b) => a.date.getTime() - b.date.getTime());
  }

  /**
   * Estimates question difficulty based on historical performance
   * This is a temporary solution until explicit difficulty ratings are added
   */
  private estimateQuestionDifficulty(questionId: number): number {
    const allAttempts = this.testHistory.flatMap(test => {
      const question = test.questions.find(q => q.id === questionId);
      if (!question) return [];
      
      const isWrong = test.wrongAnswers.some(wa => wa.question.id === questionId);
      return [{
        isCorrect: !isWrong,
        timeSpent: test.timeTaken || 0
      }];
    });

    if (allAttempts.length === 0) return 5; // Default medium difficulty

    // Calculate difficulty based on:
    // 1. Success rate (70% weight)
    // 2. Average completion time (30% weight)
    const successRate = allAttempts.filter(a => a.isCorrect).length / allAttempts.length;
    const avgTime = allAttempts.reduce((sum, a) => sum + a.timeSpent, 0) / allAttempts.length;
    const expectedTime = TimeUtils.getExpectedTime(5); // Using baseline medium difficulty
    const timeRatio = Math.min(avgTime / expectedTime, 2); // Cap at 2x expected time

    // Convert to 1-10 scale
    const difficultyScore = (
      (1 - successRate) * 0.7 + // Higher failure rate = higher difficulty
      (timeRatio - 1) * 0.3     // Longer time = higher difficulty
    ) * 10;

    return Math.max(1, Math.min(10, Math.round(difficultyScore)));
  }

  /**
   * Calculates learning style match score
   * Currently simplified for text-only questions
   * TODO: Add question parameter back when additional question formats are added
   */
  private calculateLearningStyleMatch(): number {
    const userStyle = this.userProfile.learningStyle;
    
    switch (userStyle) {
      case LearningStyle.READING_WRITING:
        return 1.0;  // Reading learners get full score
      case LearningStyle.VISUAL:
        return 0.9;  // Visual learners do well with text too
      case LearningStyle.AUDITORY:
      case LearningStyle.KINESTHETIC:
        return 0.8;  // Other styles can still learn from text
      default:
        return 0.85; // Default case
    }
  }

  /**
   * Calculates weight based on wrong answer history
   */
  private calculateWrongAnswerWeight(questionId: number): number {
    const wrongAnswerCount = this.wrongAnswers.filter(
      wa => wa.question.id === questionId
    ).length;

    if (wrongAnswerCount > 1) return PRIORITY_WEIGHTS.PERFORMANCE.WRONG_MULTIPLE_TIMES;
    if (wrongAnswerCount === 1) return PRIORITY_WEIGHTS.PERFORMANCE.WRONG_ONCE;
    
    const attempts = this.getQuestionAttempts(questionId);
    const avgTime = AttemptAnalyzer.getAverageTime(attempts);
    const expectedTime = TimeUtils.getExpectedTime(this.adaptiveState.currentDifficulty);
    
    return avgTime > expectedTime * 1.5 
      ? PRIORITY_WEIGHTS.PERFORMANCE.CORRECT_WITH_DIFFICULTY
      : PRIORITY_WEIGHTS.PERFORMANCE.CORRECT_QUICKLY;
  }

  /**
   * Identifies specific topics within a category that need focus
   */
  private identifyFocusAreas(category: string): string[] {
    const categoryQuestions = this.testHistory
      .flatMap(test => test.questions)
      .filter(q => q.category === category);

    const topicPerformance = new Map<string, { correct: number, total: number }>();
    
    categoryQuestions.forEach(question => {
      const isWrong = this.wrongAnswers.some(wa => wa.question.id === question.id);
      const topic = question.category || 'general';
      
      const current = topicPerformance.get(topic) || { correct: 0, total: 0 };
      topicPerformance.set(topic, {
        correct: current.correct + (isWrong ? 0 : 1),
        total: current.total + 1
      });
    });

    // Return topics with less than 70% success rate
    return Array.from(topicPerformance.entries())
      .filter(([_, stats]) => (stats.correct / stats.total) < 0.7)
      .map(([topic]) => topic);
  }

  /**
   * Calculates recommended weekly study hours based on exam proximity
   */
  private calculateWeeklyStudyHours(daysUntilExam: number): number {
    const baseHours = 15; // Base weekly hours
    const urgencyMultiplier = Math.max(1, 30 / daysUntilExam); // Increase hours as exam approaches
    
    return Math.min(40, Math.ceil(baseHours * urgencyMultiplier));
  }

  /**
   * Generates milestone targets based on current performance and exam date
   */
  private generateMilestones(daysUntilExam: number): Milestone[] {
    const milestones: Milestone[] = [];
    const weeksUntilExam = Math.ceil(daysUntilExam / 7);
    const currentAverage = this.calculateCurrentAverage();
    const targetScore = 0.85; // 85% target score
    
    const weeklyImprovement = (targetScore - currentAverage) / weeksUntilExam;
    
    for (let week = 1; week <= weeksUntilExam; week++) {
      const milestoneDate = new Date();
      milestoneDate.setDate(milestoneDate.getDate() + (week * 7));
      
      milestones.push({
        date: milestoneDate,
        targetScore: currentAverage + (weeklyImprovement * week),
        categoriesComplete: this.predictCompletedCategories(week),
        recommendedActions: this.generateRecommendedActions(week, weeksUntilExam)
      });
    }

    return milestones;
  }

  /**
   * Calculates current average score across recent tests
   */
  private calculateCurrentAverage(): number {
    const recentTests = this.testHistory
      .slice(-5) // Consider last 5 tests
      .filter(test => test.date); // Ensure valid date
      
    if (recentTests.length === 0) return 0;
    
    return recentTests.reduce((sum, test) => sum + test.percentage, 0) / recentTests.length;
  }

  /**
   * Predicts which categories should be completed by a given week
   */
  private predictCompletedCategories(weekNumber: number): string[] {
    // Sort categories by current strength
    const sortedCategories = Array.from(this.adaptiveState.categoryStrengths.entries())
      .sort(([, a], [, b]) => 
        (b.correctCount / b.totalCount) - (a.correctCount / a.totalCount)
      );

    // Predict completion based on current performance and week number
    const categoriesPerWeek = Math.ceil(sortedCategories.length / 8); // Complete all categories in 8 weeks
    return sortedCategories
      .slice(0, categoriesPerWeek * weekNumber)
      .map(([category]) => category);
  }

  /**
   * Generates recommended actions based on study progress
   */
  private generateRecommendedActions(currentWeek: number, totalWeeks: number): string[] {
    const actions: string[] = [];
    const progress = currentWeek / totalWeeks;

    if (progress < 0.3) {
      actions.push("Focus on understanding core concepts");
      actions.push("Complete content review for main categories");
    } else if (progress < 0.6) {
      actions.push("Increase practice test frequency");
      actions.push("Review wrong answers in detail");
    } else if (progress < 0.9) {
      actions.push("Focus on weak areas identified by practice tests");
      actions.push("Take full-length practice exams");
    } else {
      actions.push("Final review of commonly missed concepts");
      actions.push("Light review and rest before exam");
    }

    return actions;
  }

  /**
   * Main method to select the next question during a test
   */
  public selectNextQuestion(availableQuestions: Question[]): Question {
    // Calculate priority scores for all available questions
    const scoredQuestions = availableQuestions
      .filter(q => !this.adaptiveState.recentlyAskedQuestions.has(q.id))
      .map(q => ({
        question: q,
        priority: this.calculateQuestionPriority(q)
      }))
      .sort((a, b) => b.priority - a.priority);

    // Select highest priority question
    const selectedQuestion = scoredQuestions[0].question;
    this.adaptiveState.recentlyAskedQuestions.add(selectedQuestion.id);
    
    return selectedQuestion;
  }

  /**
   * Update adaptive state after each question attempt
   */
  public updateWithResult(question: Question, isCorrect: boolean, timeSpent: number): void {
    // Update difficulty based on performance
    this.updateDifficulty(isCorrect, timeSpent);
    
    // Update category performance
    const categoryPerformance = this.adaptiveState.categoryStrengths.get(question.category) || {
      category: question.category,
      correctCount: 0,
      totalCount: 0,
      averageTime: 0,
      lastAttempted: new Date()
    };

    categoryPerformance.totalCount++;
    if (isCorrect) categoryPerformance.correctCount++;
    categoryPerformance.averageTime = 
      (categoryPerformance.averageTime * (categoryPerformance.totalCount - 1) + timeSpent) / 
      categoryPerformance.totalCount;
    categoryPerformance.lastAttempted = new Date();

    this.adaptiveState.categoryStrengths.set(question.category, categoryPerformance);
  }

  /**
   * Calculate recommended pace based on remaining time
   */
  private calculateRecommendedPace(daysUntilExam: number): number {
    const totalQuestions = 170; // ASWB exam length
    const recommendedPracticeQuestions = Math.ceil((totalQuestions * 3) / daysUntilExam);
    return Math.min(50, Math.max(10, recommendedPracticeQuestions)); // Between 10-50 questions per day
  }

  /**
   * Get recommended resources for a category
   */
  private getRecommendedResources(category: string): string[] {
    // This could be expanded with actual resource links/references
    const resources = [
      "ASWB Study Guide Chapter",
      "Practice Questions Set",
      "Video Lectures",
      "Flashcard Deck"
    ];
    
    return resources.map(resource => `${resource} for ${category}`);
  }

  /**
   * Calculate average question time across recent tests
   */
  private calculateAverageQuestionTime(): number {
    const recentTests = this.testHistory.slice(-3);
    const totalTime = recentTests.reduce((sum, test) => sum + (test.timeTaken ?? 0), 0);
    return totalTime / recentTests.length;
  }

  /**
   * Generate time management advice based on user performance
   */
  private generateTimeManagementAdvice(): string {
    // This could be expanded with actual time management advice
    return "Try to answer questions within the recommended time per question.";
  }

  /**
   * Calculate performance trend based on recent tests
   */
  private calculatePerformanceTrend(recentTests: TestResult[]): 'improving' | 'steady' | 'declining' {
    const recentSuccessRate = recentTests.reduce((sum, test) => sum + test.percentage, 0) / recentTests.length;
    const previousSuccessRate = this.testHistory.slice(-6).reduce((sum, test) => sum + test.percentage, 0) / 6;

    if (recentSuccessRate > previousSuccessRate) return 'improving';
    if (recentSuccessRate < previousSuccessRate) return 'declining';
    return 'steady';
  }

  /**
   * Calculate practice frequency based on user performance
   */
  private calculatePracticeFrequency(): string {
    // This could be expanded with actual practice frequency advice
    return "Practice at least 30 minutes per day.";
  }

  /**
   * Get current difficulty level (1-10)
   */
  public getCurrentDifficulty(): number {
    return this.adaptiveState.currentDifficulty;
  }

  /**
   * Get number of consecutive correct answers
   */
  public getConsecutiveCorrect(): number {
    return this.adaptiveState.consecutiveCorrect;
  }

  /**
   * Get consecutive incorrect answers
   */
  public getConsecutiveIncorrect(): number {
    return this.adaptiveState.consecutiveIncorrect;
  }

  /**
   * Get category strengths with formatted data for display
   */
  public getCategoryStrengths(): { 
    category: string; 
    successRate: number;
    averageTime: number;
    totalAttempts: number;
  }[] {
    return Array.from(this.adaptiveState.categoryStrengths.entries())
      .map(([category, performance]) => ({
        category,
        successRate: performance.correctCount / performance.totalCount,
        averageTime: performance.averageTime,
        totalAttempts: performance.totalCount
      }))
      .sort((a, b) => b.successRate - a.successRate);
  }
}

// Helper interfaces for study plan generation
interface StudyPlan {
  weeklyHours: number;
  categoryPlans: CategoryPlan[];
  recommendedPace: number;
  milestones: Milestone[];
}

interface CategoryPlan {
  category: string;
  hoursPerWeek: number;
  focusAreas: string[];
  recommendedResources: string[];
}

interface Milestone {
  date: Date;
  targetScore: number;
  categoriesComplete: string[];
  recommendedActions: string[];
}

// Example usage during a test session:
/*
const engine = new AdaptiveLearningEngine(userProfile, testHistory, wrongAnswers);

// Start test session
while (testInProgress) {
  // Get next question
  const nextQuestion = engine.selectNextQuestion(availableQuestions);
  
  // After user answers
  const result = await getUserAnswer(nextQuestion);
  engine.updateWithResult(nextQuestion, result.isCorrect, result.timeSpent);
  
  // Periodically show insights
  if (shouldShowInsights) {
    const insights = engine.generateInsights();
    displayInsights(insights);
  }
}
*/