import React from 'react';
import { QuizProgress } from '../models/User';
import AnswerOptions from './AnswerOptions';

interface QuizComponentProps {
  quizProgress: QuizProgress;
  handleAnswer: (answer: string) => void;
  nextQuestion: () => void;
  showHint: boolean;
  setShowHint: (show: boolean) => void;
  selectedAnswer: string | null;
  showResult: boolean;
  currentExplanation: string;
  handleExplanationChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleSaveAndContinue: () => void;
  isSubscriber: boolean;
}

export const QuizComponent: React.FC<QuizComponentProps> = ({
  quizProgress,
  handleAnswer,
  nextQuestion,
  showHint,
  setShowHint,
  selectedAnswer,
  showResult,
  currentExplanation,
  handleExplanationChange,
  handleSaveAndContinue,
  isSubscriber
}) => {
  const currentQuestion = quizProgress?.questions[quizProgress.currentQuestionIndex];

  return (
    <div className={`${isSubscriber ? 'subscriber-quiz' : 'free-quiz'} space-y-6`}>
      <p className="question-text text-lg text-gray-800 dark:text-white">{currentQuestion.question_text}</p>
      
      {currentQuestion.hint && currentQuestion.hint.trim() !== "" && (
        <div className="mt-4">
          <button 
            className="hint-button bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200" 
            onClick={() => setShowHint(!showHint)}
          >
            {showHint ? 'Hide Hint' : 'Show Hint'}
          </button>
          {showHint && (
            <p className="hint-text mt-2 text-yellow-600 dark:text-yellow-300">{currentQuestion.hint}</p>
          )}
        </div>
      )}

      <AnswerOptions
        currentQuestion={currentQuestion}
        selectedAnswer={selectedAnswer}
        showResult={showResult}
        handleAnswer={handleAnswer}
        isCorrect={(option) => option === currentQuestion.correct_answer}
      />

      {showResult && (
        <div className="result-container mt-6 p-4 bg-white dark:bg-gray-700 rounded-lg shadow">
          {selectedAnswer !== currentQuestion.correct_answer ? (
            <>
              <div className="explanation-section space-y-4">
                <p className="text-red-500 font-semibold">Incorrect. The correct answer is: {currentQuestion.correct_answer}</p>
                <p className="text-gray-700 dark:text-white"><strong>Explanation:</strong> {currentQuestion.explanation}</p>
                <p className="font-bold text-blue-600 dark:text-blue-400">Create a note to help remember this concept:</p>
                <textarea
                  value={currentExplanation}
                  onChange={handleExplanationChange}
                  placeholder="Write your own notes about this question and answer..."
                  rows={4}
                  className="w-full p-2 bg-gray-100 dark:bg-gray-600 text-gray-800 dark:text-white rounded border border-gray-300 dark:border-gray-500"
                />
              </div>
              <div className="button-container mt-4">
                <button 
                  className="save-button bg-green-500 text-white px-6 py-2 rounded hover:bg-green-600 transition-colors duration-200" 
                  onClick={handleSaveAndContinue}
                >
                  Save & Continue
                </button>
              </div>
            </>
          ) : (
            <>
              <p className="text-green-500 font-semibold mb-4">Correct!</p>
              <div className="button-container">
                <button 
                  className="next-button bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600 transition-colors duration-200" 
                  onClick={nextQuestion}
                >
                  {isSubscriber ? 'Continue' : 'Next Question'}
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default QuizComponent;
