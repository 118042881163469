import React, { useEffect } from 'react';

const Feedback: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//embed.typeform.com/next/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="typeform-container">
      <div 
        data-tf-live="01J845S3YDWGYDHY0Q5KN3CPBF"
        data-tf-overflow="hidden"
        data-tf-full-screen
      ></div>
    </div>
  );
};

export default Feedback;
