import { useNavigate } from 'react-router-dom';
import { getAllQuizzes } from '../../utils/FirebaseUtils';
import React, { useState, useEffect } from 'react';
import { QuizProgress } from '../../models/User';
import { BookOpen, Target, Folder } from 'lucide-react';
import { logAnalyticsEvent, ANALYTICS_EVENTS } from '../../utils/Analytics';

interface QuizActionsProps {
    isSubscribed: boolean;
}

const QuizActions: React.FC<QuizActionsProps> = ({ isSubscribed }) => {
    const navigate = useNavigate();
    const [activeQuizzes, setActiveQuizzes] = useState<QuizProgress[]>([]);

    useEffect(() => {
        const loadActiveQuizzes = async () => {
            const quizzes = await getAllQuizzes();
            const activeQuizzesList = Object.entries(quizzes)
                .map(([id, quiz]) => ({
                    ...quiz,
                    quizId: Number(id)
                }))
                .filter(quiz => quiz.currentQuestionIndex < quiz.questions.length)
                .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
            setActiveQuizzes(activeQuizzesList);
        };
        loadActiveQuizzes();
    }, []);

    const getQuizIcon = (type: string) => {
        switch (type) {
            case 'full':
                return <BookOpen className="w-5 h-5" />;
            case 'category':
                return <Folder className="w-5 h-5" />;
            default:
                return <Target className="w-5 h-5" />;
        }
    };

    const getQuizLabel = (quiz: QuizProgress) => {
        switch (quiz.type) {
            case 'full':
                return 'Full Practice Exam';
            case 'category':
                return `Category: ${quiz.category}`;
            case 'sample':
                return '30 Question Quiz';
            default:
                return 'Quiz';
        }
    };

    const handleContinueQuiz = (quizId: number) => {
        const quiz = activeQuizzes.find(q => q.quizId === quizId);
        logAnalyticsEvent(ANALYTICS_EVENTS.CONTINUE_SAVED_QUIZ, {
            quizId,
            quizType: quiz?.type || 'sample',
            progress: quiz ? Math.round((quiz.currentQuestionIndex / quiz.questions.length) * 100) : 0,
            questionCount: quiz?.questions.length,
            timeElapsed: quiz?.date ? Date.now() - new Date(quiz.date).getTime() : 0,
            isSubscribed
        });
        navigate(`/quiz/${quizId}`);
    };

    return (
        <div className="h-full overflow-hidden flex flex-col">
            {activeQuizzes.length > 0 && (
                <div className="flex-1 min-h-0">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-3">
                        Continue Previous Quizzes ({activeQuizzes.length})
                    </h3>
                    <div className="overflow-y-auto max-h-[150px] pr-2 space-y-2">
                        {activeQuizzes.map((quiz) => (
                            <button
                                key={quiz.quizId}
                                onClick={() => handleContinueQuiz(quiz.quizId)}
                                className="w-full text-left p-3 bg-white dark:bg-gray-800 rounded-lg 
                                         shadow hover:shadow-md transition-all border border-gray-200 
                                         dark:border-gray-700 flex items-center justify-between"
                            >
                                <div className="flex items-center space-x-3 min-w-0">
                                    <div className="text-gray-600 dark:text-gray-400 flex-shrink-0">
                                        {getQuizIcon(quiz.type || 'sample')}
                                    </div>
                                    <div className="min-w-0">
                                        <div className="font-medium text-gray-900 dark:text-gray-100 truncate">
                                            {getQuizLabel(quiz)}
                                        </div>
                                        <div className="text-xs text-gray-500 dark:text-gray-400">
                                            Started {new Date(quiz.date).toLocaleDateString()}
                                        </div>
                                    </div>
                                </div>
                                <div className="text-right flex-shrink-0 ml-2">
                                    <div className="text-sm font-medium text-blue-600 dark:text-blue-400">
                                        {Math.round((quiz.currentQuestionIndex / quiz.questions.length) * 100)}%
                                    </div>
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default QuizActions; 