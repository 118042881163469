import React from 'react';
import { DashboardCard } from './ui/dashboard-card';
import { Trophy, Clock, Target, Brain } from 'lucide-react';
import { AdaptiveLearningEngine } from '../utils/adaptiveLearning';

interface QuizSummaryProps {
  engine: AdaptiveLearningEngine;
  totalQuestions: number;
  timeSpent: number;
  onRestartQuiz: () => void;
}

const QuizSummary: React.FC<QuizSummaryProps> = ({
  engine,
  totalQuestions,
  timeSpent,
  onRestartQuiz
}) => {
  const insights = engine.generateInsights();
  const categoryStrengths = engine.getCategoryStrengths();
  
  // Calculate overall performance metrics
  const correctAnswers = categoryStrengths.reduce(
    (total, cat) => total + (cat.successRate * cat.totalAttempts), 
    0
  );
  const overallScore = Math.round((correctAnswers / totalQuestions) * 100);
  const averageTimePerQuestion = Math.round(timeSpent / totalQuestions);

  return (
    <div className="max-w-4xl mx-auto space-y-6 p-4">
      <h2 className="text-2xl font-bold text-center mb-8">Quiz Complete!</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <DashboardCard icon={Trophy} title="Overall Performance">
          <div className="text-center">
            <div className="text-4xl font-bold mb-2">{overallScore}%</div>
            <p className="text-sm text-gray-500">
              {correctAnswers} correct out of {totalQuestions} questions
            </p>
          </div>
        </DashboardCard>

        <DashboardCard icon={Clock} title="Time Analysis">
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <span>Total Time:</span>
              <span className="font-semibold">{Math.round(timeSpent / 60)} minutes</span>
            </div>
            <div className="flex justify-between items-center">
              <span>Avg. Time per Question:</span>
              <span className="font-semibold">{averageTimePerQuestion} seconds</span>
            </div>
          </div>
        </DashboardCard>

        <DashboardCard icon={Brain} title="Strongest Categories">
          <div className="space-y-2">
            {categoryStrengths
              .filter(cat => cat.successRate > 0)
              .sort((a, b) => b.successRate - a.successRate)
              .slice(0, 3)
              .map((category, index) => (
                <div key={index} className="flex justify-between items-center">
                  <span className="truncate">{category.category}</span>
                  <span className="font-semibold text-green-500">
                    {Math.round(category.successRate * 100)}%
                  </span>
                </div>
              ))}
          </div>
        </DashboardCard>

        <DashboardCard icon={Target} title="Areas for Improvement">
          <div className="space-y-2">
            {insights.weakCategories
              .slice(0, 3)
              .map((category, index) => (
                <div key={index} className="flex justify-between items-center">
                  <span className="truncate">{category.category}</span>
                  <span className="font-semibold text-red-500">
                    {Math.round(category.successRate * 100)}%
                  </span>
                </div>
              ))}
          </div>
        </DashboardCard>
      </div>

      <div className="flex justify-center mt-8">
        <button
          onClick={onRestartQuiz}
          className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 
                   transition-colors font-semibold"
        >
          Start New Quiz
        </button>
      </div>
    </div>
  );
};

export default QuizSummary; 