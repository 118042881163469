import React from 'react';
import { Helmet } from 'react-helmet';

const AboutUs: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>About Us | Social Work Test Prep Academy</title>
        <meta name="description" content="Learn about the founders of Social Work Test Prep Academy and our mission to support social workers in their journey toward professional growth." />
        <meta property="og:title" content="About Us | Social Work Test Prep Academy" />
        <meta property="og:description" content="Discover the story behind Social Work Test Prep Academy and our commitment to empowering social workers." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="new-home-container">
        <div className="new-home-content">
          <div className="new-home-inner">
            <h1 className="text-4xl font-bold mb-8 text-center text-gray-800 dark:text-gray-200">ABOUT US</h1>
            
            <div className="grid md:grid-cols-2 gap-8">
              <CoFounderSection
                name="Jordan Coin Jackson"
                title="Co Founder"
                imageSrc="/jordan-image.png"
                description="Our co-founder is a self-made corporate software engineer with a passion for using technology to make a positive impact. With a deep understanding of the challenges faced by social workers, having dedicated their skills and experience to creating a platform that supports social workers in their professional growth journey."
                quote="My goal is to empower social workers by providing accessible, high-quality test preparation resources, ensuring every social worker has the tools they need to succeed. Driven by a commitment to service, I believe in the power of education and technology to transform lives and communities."
              />
              
              <CoFounderSection
                name="Pamela Coin, LICSW"
                title="Co-founder"
                imageSrc="/pamela-image.png"
                description="Our co-founder is a proud University of Michigan graduate and a dedicated therapist based in Washington, D.C. With firsthand experience in the field."
                quote="I understand social workers' hurdles, especially regarding licensure. Frustrated by the lack of legislative support for change, I have set out to make a difference by helping social workers succeed on their exams. I have played a key role in editing, creating, and reviewing content on our platform, ensuring it is thorough and practical. The questions are AI-assisted, curated with statistical references to varying p-value difficulty levels. While I am not formally trained in psychometrics, I am dedicated to providing social workers with the tools they need to overcome challenges and reach their professional goals."
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

interface CoFounderSectionProps {
  name: string;
  title: string;
  imageSrc: string;
  description: string;
  quote?: string; // Make quote optional
}

const CoFounderSection: React.FC<CoFounderSectionProps> = ({ name, title, imageSrc, description, quote }) => {
  return (
    <div className="bg-gray-50 dark:bg-gray-700 p-6 rounded-lg shadow-md">
      <div className="aspect-w-16 aspect-h-9 mb-4">
        <img src={imageSrc} alt={name} className="w-full h-full object-cover rounded-lg" />
      </div>
      <h2 className="text-2xl font-bold mb-2 text-gray-800 dark:text-gray-200">{name}</h2>
      <h3 className="text-xl text-gray-600 dark:text-gray-300 mb-4">{title}</h3>
      <div className="text-gray-700 dark:text-gray-200">
        <p>{description}</p>
        {quote && (
          <blockquote className="border-l-4 border-blue-500 pl-4 my-4 italic">
            {quote}
          </blockquote>
        )}
      </div>
    </div>
  );
};

export default AboutUs;
