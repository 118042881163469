import { QUIZ_PROGRESS_PREFIX, TEST_RESULTS_KEY, WRONG_ANSWERS_KEY } from '../constants';
import questionsData from './questions.json';
import { Question } from '../models/Quiz'
import { QuizProgress, TestResult, User as AppUser } from '../models/User'
import { auth, db } from '../firebase';
import { deleteUser } from 'firebase/auth';
import { doc, getDoc, updateDoc, deleteDoc, collection, query, getDocs, arrayUnion, deleteField, limit } from 'firebase/firestore';
import { FirebaseError } from 'firebase/app';
import { fetchQuestions } from './FirebaseUtils';
import { User as FirebaseUser } from 'firebase/auth';


const typedQuestionsData: Question[] = questionsData.map(q => ({
  ...q,
  category: q.Category,
  question_text: q.Questions,
  option_a: q.A,
  option_b: q.B,
  option_c: q.C,
  option_d: q.D,
  option_e: '',
  correct_answer: q["CORRECT ANSWER"],
  explanation: q.Explanation,
  id: q.id,
  hint: q.HINT,
  selected_answer: null
}));

// Create random number generator so we don't fall back to only 0. 
// Would like a low chance of duplicate ids for number until we clean up data collection

// Add this new function to sanitize the question data
export const sanitizeQuestion = (question: any): Question => {
  return {
    id: question.id || Date.now(), // Fallback to timestamp if id is missing
    category: question.category || question.Category,
    question_text: question.question_text || question.Questions,
    option_a: question.option_a || question.A,
    option_b: question.option_b || question.B,
    option_c: question.option_c || question.C,
    option_d: question.option_d || question.D,
    option_e: question.option_e || question.E || '',
    correct_answer: question.correct_answer || question["CORRECT ANSWER"],
    explanation: question.explanation || question.Explanation || '',
    hint: question.hint || question.HINT || '',
    selected_answer: question.selected_answer || null
  };
};

export const saveQuizProgress = async (quizId: number, progress: QuizProgress): Promise<void> => {
  const user = auth.currentUser;
  if (!user) {
    localStorage.setItem(`${QUIZ_PROGRESS_PREFIX}${quizId}`, JSON.stringify(progress));
    return;
  }

  try {
    const userDocRef = doc(db, 'users', user.uid);
    const sanitizedProgress = {
      ...progress,
      type: progress.type || 'sample',
      questionIds: progress.questionIds || progress.questions.map(q => q.id)
    };

    // Update both new and legacy structures for backward compatibility
    await updateDoc(userDocRef, { 
      [`quizzes.${quizId}`]: sanitizedProgress,
      quizProgress: deleteField() // Remove legacy structure after migrating
    });
  } catch (error) {
    console.error('Error saving quiz progress:', error);
    // localStorage.setItem(`${QUIZ_PROGRESS_PREFIX}${quizId}`, JSON.stringify(progress));
    throw new Error('Failed to save quiz progress');
  }
};

export const loadQuizProgress = async (quizId: number, isSubscribed: boolean): Promise<QuizProgress | null> => {
  const user = auth.currentUser;
  if (!user) {
    const localProgress = localStorage.getItem(`${QUIZ_PROGRESS_PREFIX}${quizId}`);
    return localProgress ? JSON.parse(localProgress) : null;
  }

  try {
    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);
    const data = userDoc.data();

    // Check new structure first
    if (data?.quizzes?.[quizId]) {
      const progress = data.quizzes[quizId] as QuizProgress;
      if (!progress.questionIds) {
        progress.questionIds = progress.questions.map(q => q.id);
      }
      return progress;
    }

    // Fallback to legacy structure
    if (data?.quizProgress) {
      const progress = data.quizProgress as QuizProgress;
      if (!progress.questionIds) {
        progress.questionIds = progress.questions.map(q => q.id);
      }
      // Migrate to new structure
      await saveQuizProgress(quizId, progress);
      return progress;
    }

    return null;
  } catch (error) {
    console.error('Error loading quiz progress:', error);
    const localProgress = localStorage.getItem(`${QUIZ_PROGRESS_PREFIX}${quizId}`);
    return localProgress ? JSON.parse(localProgress) : null;
  }
};

export const hasSavedQuizProgress = async (): Promise<{ inProgress: boolean; quizId: number | null }> => {
  const user = auth.currentUser;
  if (!user) {
    const progressKeys = Object.keys(localStorage).filter(key => key.startsWith(QUIZ_PROGRESS_PREFIX));
    if (progressKeys.length === 0) return { inProgress: false, quizId: null };
    const latestQuizId = progressKeys.sort().pop()?.split('_')[1] || null;
    return { inProgress: !!Number(latestQuizId), quizId: Number(latestQuizId) };
  }

  try {
    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);
    const data = userDoc.data();
    const quizProgress = data?.quizProgress;

    if (!quizProgress) return { inProgress: false, quizId: null };

    return { inProgress: true, quizId: quizProgress.quizId };
  } catch (error) {
    console.error('Error checking for saved quiz progress:', error);
    return { inProgress: false, quizId: null };
  }
};

export const hasCompletedTests = async (): Promise<boolean> => {
  const user = auth.currentUser;
  if (!user) {
    const completedTests = JSON.parse(localStorage.getItem(TEST_RESULTS_KEY) || '[]');
    return completedTests.length > 0;
  }

  try {
    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);
    const data = userDoc.data();
    if (data && data.testResults) {
      // Ensure testResults is always treated as an array
      const testResults = Array.isArray(data.testResults) ? data.testResults : [data.testResults];
      return testResults.length > 0;
    }
    return false;
  } catch (error) {
    console.error('Error checking for completed tests:', error);
    return false;
  }
};

export const getLatestQuizId = (): string | null => {
  try {
    const progressKeys = Object.keys(localStorage).filter(key => key.startsWith(QUIZ_PROGRESS_PREFIX));
    if (progressKeys.length === 0) return null;
    const latestKey = progressKeys.sort().pop();
    if (!latestKey) return null;
    const quizId = latestKey.split('_')[1];
    return quizId || null;
  } catch (error) {
    console.error('Error getting latest quiz ID:', error);
    throw new Error('Failed to get latest quiz ID');
  }
};

export const saveTestResult = async (result: TestResult): Promise<void> => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  try {
    // Remove any undefined values from the result object
    const sanitizedResult = JSON.parse(JSON.stringify(result));

    const userDocRef = doc(db, 'users', user.uid);
    await updateDoc(userDocRef, {
      testResults: arrayUnion(sanitizedResult)
    });
  } catch (error) {
    console.error('Error saving test result:', error);
    throw new Error('Failed to save test result');
  }
};

export const clearQuizProgress = async (quizId: number): Promise<void> => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  try {
    const userDocRef = doc(db, 'users', user.uid);
    await updateDoc(userDocRef, {
      [`quizzes.${quizId}`]: deleteField(),
      quizProgress: deleteField() // Clean up legacy field if it exists
    });
  } catch (error) {
    console.error('Error clearing quiz progress:', error);
    throw new Error('Failed to clear quiz progress');
  }
};

export const loadTestResults = async (): Promise<TestResult[]> => {
  const user = auth.currentUser;
  if (!user) {
    const localResults = localStorage.getItem(TEST_RESULTS_KEY);
    return localResults ? JSON.parse(localResults) : [];
  }

  try {
    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);
    const data = userDoc.data();
    if (data && data.testResults) {
      console.log("Loaded test results from Firestore:", data.testResults);
      return data.testResults;
    } else {
      console.log("No test results found in Firestore");
      return [];
    }
  } catch (error) {
    console.error('Error loading test results from Firestore:', error);
    const localResults = localStorage.getItem(TEST_RESULTS_KEY);
    return localResults ? JSON.parse(localResults) : [];
  }
};

export const initializeNewQuiz = async (): Promise<QuizProgress> => {
  const quizId = Date.now();
  const questions: Question[] = [...typedQuestionsData];
  const randomizedQuestions = shuffleArray(questions).slice(0, 30);
  const quizProgress: QuizProgress = {
    quizId,
    currentQuestionIndex: 0,
    initialQuestionIndex: 0,
    score: 0,
    date: new Date().toISOString(),
    wrongAnswers: [],
    questions: randomizedQuestions.map(sanitizeQuestion),
    questionIds: randomizedQuestions.map(q => q.id)
  };
  await saveQuizProgress(quizId, quizProgress);
  return quizProgress;
};

export const continueExistingQuiz = async (): Promise<number | null> => {
  const { inProgress, quizId } = await hasSavedQuizProgress();
  if (inProgress && quizId) {
    return quizId;
  }
  return null;
};

export const clearAllQuizData = async (): Promise<void> => {
  if (process.env.NODE_ENV !== 'development') {
    console.warn('clearAllQuizData is only available in development mode');
    return;
  }

  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  try {
    // Clear Firestore data
    const userDocRef = doc(db, 'users', user.uid);
    await deleteDoc(userDocRef);

    // Clear Firebase auth user
    await deleteUser(user);
    console.log('Firebase user deleted successfully');

    // Sign out the user after deletion
    await auth.signOut();
    console.log('Signed out of Firebase');

    // Dispatch an event to notify other components of the data clear
    window.dispatchEvent(new Event('quizDataCleared'));
  } catch (error) {
    console.error('Error clearing all quiz data:', error);
    throw new Error('Failed to clear all quiz data');
  }
};

const checkLocalStorageForDataToClear = (): boolean => {
  return Object.keys(localStorage).some(key => 
    key.startsWith(QUIZ_PROGRESS_PREFIX) || 
    key === TEST_RESULTS_KEY || 
    key === WRONG_ANSWERS_KEY
  );
};

export const checkForDataToClear = async (): Promise<boolean> => {
  const user = auth.currentUser;
  if (!user) {
    return checkLocalStorageForDataToClear();
  }

  try {
    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);
    return userDoc.exists();
  } catch (error) {
    console.error('Error checking for data to clear:', error);
    if (error instanceof FirebaseError && (error.code === 'unavailable' || error.code === 'failed-precondition')) {
      console.log('Firebase is unavailable. Falling back to local storage check.');
      return checkLocalStorageForDataToClear();
    }
    return false;
  }
};

export const shuffleArray = <T,>(array: T[]): T[] => {
  try {
    return [...array].sort(() => Math.random() - 0.5);
  } catch (error) {
    console.error('Error shuffling array:', error);
    throw new Error('Failed to shuffle array');
  }
};

interface QuizInitResult {
  quizId: number;
  category?: string;
}

export const initializeQuizForUser = async (
  isSubscribed: boolean, 
  quizType: 'category' | 'full' | 'sample' = 'sample',
  category?: string
): Promise<QuizInitResult> => {
  try {
    let questions = await fetchQuestions(
      isSubscribed,
      [],
      category,
      quizType);
    
    questions = shuffleArray(questions);

    const quizProgress: QuizProgress = {
      quizId: Date.now(),
      currentQuestionIndex: 0,
      initialQuestionIndex: 0,
      score: 0,
      date: new Date().toISOString(),
      wrongAnswers: [],
      questions,
      questionIds: questions.map(q => q.id),
      type: quizType,
      category
    };

    await saveQuizProgress(quizProgress.quizId, quizProgress);
    
    return {
      quizId: quizProgress.quizId,
      category
    };
  } catch (error) {
    console.error('Error initializing quiz:', error);
    throw error;
  }
};

// Add this new function to fetch question IDs
const fetchQuestionIds = async (count: number): Promise<number[]> => {
  try {
    const questionsRef = collection(db, 'questions');
    const q = query(questionsRef, limit(count));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => {
      const data = doc.data();
      return data.id as number;
    });
  } catch (error) {
    console.error('Error fetching question IDs:', error);
    throw new Error('Failed to fetch question IDs');
  }
};

export const migrateLocalProgress = async (firebaseUser: FirebaseUser): Promise<void> => {
  if (!firebaseUser.uid) {
    console.error('No user ID available for migration');
    return;
  }

  const localProgressKeys = Object.keys(localStorage)
    .filter(key => key.startsWith(QUIZ_PROGRESS_PREFIX));
    
  for (const key of localProgressKeys) {
    try {
      const progressStr = localStorage.getItem(key);
      if (!progressStr) continue;
      
      const progress = JSON.parse(progressStr) as QuizProgress;
      const quizId = parseInt(key.replace(QUIZ_PROGRESS_PREFIX, ''));
      
      if (!isNaN(quizId)) {
        // Use the firebaseUser.uid when saving to ensure it's tied to the correct user
        const userDocRef = doc(db, 'users', firebaseUser.uid);
        await updateDoc(userDocRef, {
          [`quizzes.${quizId}`]: progress,
          quizProgress: deleteField() // Remove legacy field if it exists
        });
        
        localStorage.removeItem(key);
        console.log(`Migrated quiz ${quizId} for user ${firebaseUser.uid}`);
      }
    } catch (error) {
      console.error(`Failed to migrate quiz from key ${key} for user ${firebaseUser.uid}:`, error);
    }
  }
};

// Add this to your app initialization or auth handling code
export const initializeAuthListener = () => {
  auth.onAuthStateChanged(async (user: FirebaseUser | null) => {
    if (user) {
      try {
        await migrateLocalProgress(user);
      } catch (error) {
        console.error('Failed to migrate local progress:', error);
      }
    }
  });
};