import React from 'react';
import { Helmet } from 'react-helmet';

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Social Work Test Prep Academy</title>
        <meta name="description" content="Read our privacy policy to understand how we collect, use, and protect your personal information when you use our ASWB LCSW exam preparation services." />
        <meta property="og:title" content="Privacy Policy | Social Work Test Prep Academy" />
        <meta property="og:description" content="Our commitment to protecting your privacy and personal information while using our ASWB LCSW exam preparation services." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="new-home-container">
        <div className="new-home-content">
          <div className="new-home-inner">
            <div className="privacy-policy">
              <h1 className="text-2xl sm:text-3xl font-bold mb-4 text-center text-gray-800 dark:text-gray-200">Privacy Policy for Social Work Test Prep Academy</h1>
              <p className="text-base sm:text-lg mb-6 text-center text-gray-600 dark:text-gray-300">Last updated: Thursday September 19th 2024</p>

              <h2 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">1. Introduction</h2>
              <p className="mb-4 text-gray-700 dark:text-gray-300">
                Welcome to Social Work Test Prep Academy (&quot;we,&quot; &quot;our,&quot; or &quot;us&quot;). We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [www.socialworktestprepacademy.com] and use our services.
              </p>

              <h2 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">2. Information We Collect</h2>
              <p className="mb-2 text-gray-700 dark:text-gray-300">We collect personal information that you provide to us such as:</p>
              <ul className="list-disc pl-5 mb-4 text-gray-700 dark:text-gray-300">
                <li>Name</li>
                <li>Email address</li>
                <li>Quiz results and progress data</li>
              </ul>

              <h2 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">3. How We Use Your Information</h2>
              <p className="mb-4 text-gray-700 dark:text-gray-300">
                We use your personal information for these purposes:
              </p>
              <ul className="list-disc pl-5 mb-4 text-gray-700 dark:text-gray-300">
                <li>To provide and maintain our service</li>
                <li>To notify you about changes to our service</li>
                <li>To allow you to participate in interactive features when you choose to do so</li>
                <li>To provide customer support</li>
                <li>To gather analysis or valuable information so that we can improve our service</li>
                <li>To monitor the usage of our service</li>
                <li>To detect, prevent and address technical issues</li>
              </ul>

              <h2 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">4. Data Retention</h2>
              <p className="mb-4 text-gray-700 dark:text-gray-300">
                We will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy.
              </p>

              <h2 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">5. Data Security</h2>
              <p className="mb-4 text-gray-700 dark:text-gray-300">
                We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure.
              </p>

              <h2 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">6. Your Data Protection Rights</h2>
              <p className="mb-4 text-gray-700 dark:text-gray-300">
                Depending on your location, you may have certain rights regarding your personal information, such as:
              </p>
              <ul className="list-disc pl-5 mb-4 text-gray-700 dark:text-gray-300">
                <li>The right to access, update or to delete the information we have on you</li>
                <li>The right of rectification</li>
                <li>The right to object</li>
                <li>The right of restriction</li>
                <li>The right to data portability</li>
                <li>The right to withdraw consent</li>
              </ul>

              <h2 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">7. Changes to This Privacy Policy</h2>
              <p className="mb-4 text-gray-700 dark:text-gray-300">
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the &quot;Last updated&quot; date.
              </p>

              <h2 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">8. Contact Us</h2>
              <p className="mb-4 text-gray-700 dark:text-gray-300">
                If you have any questions about this Privacy Policy, please contact us at:
                support@socialworktestprepacademy.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
