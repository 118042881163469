import { format } from 'date-fns';
import { TestResult } from "../../models/User";
import React, { useState } from 'react';

interface TestScoresProps {
    testResults: TestResult[];
}

const TestScores: React.FC<TestScoresProps> = ({ testResults }) => {
    const latestResult = testResults && testResults.length > 0 ? testResults[testResults.length - 1] : null;

    return (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6 border border-gray-200 dark:border-gray-600 h-full overflow-auto">
            <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">Latest Score</h2>
            {latestResult ? (
                <>
                    <p className="text-3xl font-bold text-green-600 dark:text-green-400">
                        {Math.round((latestResult.score / latestResult.totalQuestions) * 100)}%
                    </p>
                    <p className="text-sm text-gray-600 dark:text-gray-400 mt-2">
                        {latestResult.score} out of {latestResult.totalQuestions} questions
                    </p>
                    <p className="text-sm text-gray-600 dark:text-gray-400 mt-2">
                        Date: {format(new Date(latestResult.date), 'MMMM d, yyyy')}
                    </p>
                </>
            ) : (
                <p className="text-gray-600 dark:text-gray-400">No tests taken yet</p>
            )}
        </div>
    );
};

export default TestScores; 