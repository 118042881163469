import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { auth } from './firebase';

const Footer: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, []);

  return (
    <footer className="bg-gray-100 dark:bg-gray-800">
      <div className="border-t border-gray-300 dark:border-gray-700"></div>
      
      <div className="container mx-auto px-4 py-6">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <nav className="flex flex-wrap justify-center space-x-4 mb-4 md:mb-0">
            <Link to="/about" className="footer-link text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100">About Us</Link>
            <Link to="/pricing" className="footer-link text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100">Pricing</Link>
            {isLoggedIn && (
              <>
                <Link to="/feedback" className="footer-link text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100">Feedback</Link>
                <Link to="/profile" className="footer-link text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100">Profile</Link>
              </>
            )}
            <Link to="/privacy-policy" className="footer-link text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100">Privacy Policy</Link>
            <Link to="/disclosures" className="footer-link text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100">Disclosures</Link>
            <a href="mailto:support@socialworktestprepacademy.com" className="footer-link text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100">
              Contact Us
            </a>
          </nav>
          <div className="flex space-x-4">
            <a href="https://www.facebook.com/profile.php?id=61565921293583" target="_blank" rel="noopener noreferrer" className="text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100">
              <FaFacebookF size={20} />
            </a>
            <a href="https://x.com/aswbtestprep" target="_blank" rel="noopener noreferrer" className="text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100">
              <FaTwitter size={20} />
            </a>
            <a href="https://www.instagram.com/socialworktestprepacademy?igsh=MTUyYzQ1MGI0Ymg1ZA%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer" className="text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100">
              <FaInstagram size={20} />
            </a>
          </div>
        </div>
        <div className="text-center text-sm text-gray-600 dark:text-gray-300 mt-4">
          &copy; 2024 JWC Holdings LLC. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
