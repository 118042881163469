import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import LoadingSpinner from './LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { 
  loadUserData, 
  loadTestResults, 
  checkSubscriptionStatus, 
  loadWrongAnswers, 
  updateWrongAnswerExplanation, 
  removeWrongAnswer, 
  updateUserProfile 
} from '../utils/FirebaseUtils';
import TestScores from './dashboard/TestScores';
import FlashCards from './dashboard/FlashCards';
import QuizActions from './dashboard/QuizActions';
import { User, UserProfile, TestResult, WrongAnswer } from '../models/User';
import { seedMockData } from '../utils/seedMockData';
import { auth } from '../firebase';
import { initializeQuizForUser } from '../utils/quizUtils';
import OnboardingModal from './OnboardingModal';
import { buildShareText, shareToSocial } from '../utils/shareUtils';
import { Calendar as CalendarIcon, Share2, XIcon, ClockIcon, LucideMessageCircleQuestion } from 'lucide-react';
import AdaptiveInsightsCard from './dashboard/AdaptiveInsightsCard';
import { mockUser } from '../utils/mockTestData';
import { logAnalyticsEvent, ANALYTICS_EVENTS } from '../utils/Analytics';

const ASWB_CATEGORIES = [
  'IA. HUMAN GROWTH AND DEVELOPMENT',
  'IB. HUMAN BEHAVIOR IN THE SOCIAL ENVIRONMENT',
  'IC. Diversity and discrimination',
  'IIA. BIOPSYCHOSOCIAL HISTORY AND COLLATERAL DATA',
  'IIB. ASSESSMENT AND DIAGNOSIS',
  'IIC. TREATMENT PLANNING',
  'IIIA. THERAPEUTIC RELATIONSHIP',
  'IIIB. THE INTERVENTION PROCESS',
  'IIIC. SERVICE DELIVERY AND MANAGEMENT OF CASES',
  'IIID. CONSULTATION AND INTERDISCIPLINARY COLLABORATION',
  'IVA. PROFESSIONAL VALUES AND ETHICAL ISSUES',
  'IVB. CONFIDENTIALITY',
  'IVC. PROFESSIONAL DEVELOPMENT AND USE OF SELF'
];

// Add new type for quiz details
type QuizTypeDetails = {
    title: string;
    description: string;
    duration: string;
    questionCount?: number;
    features: string[];
};

const QUIZ_DETAILS: Record<string, QuizTypeDetails> = {
    category: {
        title: 'Category Practice',
        description: 'Focus on mastering specific ASWB exam topics',
        duration: 'Self-paced practice',
        features: [
            'Questions from selected category only',
            'Detailed explanations for each answer',
            'Track progress by category',
            'Pause and resume anytime'
        ]
    },
    full: {
        title: 'Full Length Test',
        description: 'Simulate the actual ASWB exam experience',
        duration: '4 hours',
        questionCount: 170,
        features: [
            'Timed test environment',
            'Questions from all categories',
            'Mirrors actual exam format',
            'Comprehensive score report'
        ]
    },
    sample: {
        title: 'Quick Quiz',
        description: 'Rapid practice session with mixed topics',
        duration: '~30 minutes',
        questionCount: 30,
        features: [
            'Mixed category questions',
            'Perfect for daily practice',
            'Quick performance feedback',
            'Focus on high-yield topics'
        ]
    }
};

const QuizConfirmationModal = ({ 
    quizType, 
    onConfirm, 
    onClose 
}: { 
    quizType: string;
    onConfirm: (category?: string) => void; 
    onClose: () => void; 
}) => {
    const details = QUIZ_DETAILS[quizType];
    const [selectedCategory, setSelectedCategory] = useState<string>('');

    return (
        <div className="fixed inset-0 bg-black/50 dark:bg-black/70 flex items-center justify-center p-4 z-50">
            <div className="bg-white dark:bg-gray-800 rounded-lg max-w-lg w-full p-6 shadow-xl">
                <div className="flex justify-between items-start mb-4">
                    <h3 className="text-xl font-bold text-gray-900 dark:text-gray-100">
                        {details.title}
                        {selectedCategory && (
                            <span className="block text-sm text-blue-600 dark:text-blue-400 mt-1">
                                {selectedCategory}
                            </span>
                        )}
                    </h3>
                    <button 
                        onClick={onClose} 
                        className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                    >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-6 w-6" />
                    </button>
                </div>

                <div className="space-y-4">
                    {quizType === 'category' && (
                        <div className="mb-4">
                            <label 
                                htmlFor="category" 
                                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                            >
                                Select Category
                            </label>
                            <select
                                id="category"
                                value={selectedCategory}
                                onChange={(e) => setSelectedCategory(e.target.value)}
                                className="w-full rounded-lg border border-gray-300 dark:border-gray-600 
                                         bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 
                                         px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500
                                         dark:focus:ring-blue-400"
                                required
                            >
                                <option value="" disabled>Choose a category...</option>
                                {ASWB_CATEGORIES.map((category) => (
                                    <option 
                                        key={category} 
                                        value={category}
                                        className="bg-white dark:bg-gray-700"
                                    >
                                        {category}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    <p className="text-gray-600 dark:text-gray-400">{details.description}</p>
                    
                    <div className="flex space-x-4 text-sm text-gray-600 dark:text-gray-400">
                        <div className="flex items-center">
                            <ClockIcon className="h-4 w-4 mr-1" />
                            {details.duration}
                        </div>
                        {details.questionCount && (
                            <div className="flex items-center">
                                <LucideMessageCircleQuestion className="h-4 w-4 mr-1" />
                                {details.questionCount} questions
                            </div>
                        )}
                    </div>

                    <div className="border-t border-gray-200 dark:border-gray-700 pt-4">
                        <h4 className="font-semibold mb-2 text-gray-800 dark:text-gray-200">Features:</h4>
                        <ul className="list-disc list-inside space-y-1 text-sm text-gray-600 dark:text-gray-400">
                            {details.features.map((feature, index) => (
                                <li key={index}>{feature}</li>
                            ))}
                        </ul>
                    </div>

                    <div className="flex space-x-3 mt-6">
                        <button
                            onClick={() => onConfirm(selectedCategory)}
                            disabled={quizType === 'category' && !selectedCategory}
                            className={`flex-1 px-4 py-2 rounded-lg transition-colors
                                ${quizType === 'category' && !selectedCategory 
                                    ? 'bg-gray-400 dark:bg-gray-600 cursor-not-allowed' 
                                    : 'bg-blue-600 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600'} 
                                text-white`}
                        >
                            Start Quiz
                        </button>
                        <button
                            onClick={onClose}
                            className="flex-1 border border-gray-300 dark:border-gray-600 
                                     px-4 py-2 rounded-lg 
                                     text-gray-700 dark:text-gray-300
                                     hover:bg-gray-50 dark:hover:bg-gray-700
                                     transition-colors"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ReviewConfirmationModal = ({ 
    onConfirm, 
    onClose 
}: { 
    onConfirm: () => void;
    onClose: () => void;
}) => {
    return (
        <div className="fixed inset-0 bg-black/50 dark:bg-black/70 flex items-center justify-center p-4 z-50">
            <div className="bg-white dark:bg-gray-800 rounded-lg max-w-lg w-full p-6 shadow-xl">
                <div className="flex justify-between items-start mb-4">
                    <h3 className="text-xl font-bold text-gray-900 dark:text-gray-100">
                        Mark as Reviewed
                    </h3>
                    <button 
                        onClick={onClose} 
                        className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                    >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-6 w-6" />
                    </button>
                </div>
                
                <p className="text-gray-600 dark:text-gray-400 mb-6">
                    Are you confident that you understand this concept and don&apos;t need to review it anymore?
                </p>

                <div className="flex space-x-3">
                    <button
                        onClick={onConfirm}
                        className="flex-1 px-4 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 
                                 dark:bg-blue-500 dark:hover:bg-blue-600 text-white"
                    >
                        Yes, I understand it
                    </button>
                    <button
                        onClick={onClose}
                        className="flex-1 border border-gray-300 dark:border-gray-600 
                                 px-4 py-2 rounded-lg text-gray-700 dark:text-gray-300
                                 hover:bg-gray-50 dark:hover:bg-gray-700"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

const Dashboard: React.FC = () => {
    const [userData, setUserData] = useState<User | null>(null);
    // const [testResults, setTestResults] = useState<TestResult[]>([]);
    // const [isSubscribed, setIsSubscribed] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [wrongAnswers, setWrongAnswers] = useState<WrongAnswer[]>([]);
    const navigate = useNavigate();
    const [flashcardToReview, setFlashcardToReview] = useState<number | null>(null);
    const [showOnboarding, setShowOnboarding] = useState(false);
    const [latestResult, setLatestResult] = useState<TestResult | null>(null);
    const [showDatePicker, setShowDatePicker] = useState(false);

    useEffect(() => {
        const loadDashboardData = async () => {
            try {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Using mock data in development mode');
                    setUserData(mockUser);
                    setShowOnboarding(!mockUser.hasCompletedOnboarding);
                    console.log('Mock wrong answers:', mockUser.totalWrongAnswers.length);
                } else {
                    // Use real Firebase data in production
                    const user = await loadUserData();
                    setUserData(user);
                    setShowOnboarding(!user?.profile?.onboardingCompleted);
                }
            } catch (error) {
                console.error('Error loading dashboard data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        loadDashboardData();
    }, [navigate]);

    // Add debug logging
    useEffect(() => {
      console.log('Subscription status:', userData?.isSubscribed);
      console.log('Loading state:', isLoading);
  }, [userData?.isSubscribed, isLoading]);

    useEffect(() => {
        if (userData?.latestTestResult) {
            setLatestResult(userData.latestTestResult);
        }
    }, [userData?.testResults]);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    // Update QuizTypes to handle category selection
    const QuizTypes = () => {
        const [showConfirmation, setShowConfirmation] = useState(false);
        const [selectedQuiz, setSelectedQuiz] = useState<string | null>(null);
        const navigate = useNavigate();

        const handleQuizSelect = (type: string) => {
            logAnalyticsEvent(ANALYTICS_EVENTS.QUIZ_TYPE_SELECTED, {
                quizType: type,
                isSubscribed: userData?.isSubscribed,
                totalQuizzesTaken: userData?.testResults?.length || 0
            });
            setSelectedQuiz(type);
            setShowConfirmation(true);
        };

        const handleConfirm = async (category?: string) => {
          try {
            const { quizId } = await initializeQuizForUser(
              userData?.subscriptionStatus == "active",
              selectedQuiz as 'category' | 'full' | 'sample',
              category
            );
            navigate(`/quiz/${quizId}`);
          } catch (error) {
            console.error('Error starting quiz:', error);
          }
          setShowConfirmation(false);
        };

        return (
            <>
                <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6 col-span-full">
                    <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">
                        Study Options
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        {/* Category Practice */}
                        <button
                            onClick={() => handleQuizSelect('category')}
                            className="p-6 rounded-lg border-2 border-blue-200 dark:border-blue-800 hover:border-blue-300 dark:hover:border-blue-700 transition-all hover:shadow-md"
                        >
                            <h3 className="text-lg font-semibold mb-2 text-gray-800 dark:text-gray-100">
                                Category Practice
                            </h3>
                            <p className="text-sm text-gray-600 dark:text-gray-400">
                                Focus on specific ASWB exam topics to strengthen your weak areas
                            </p>
                        </button>

                        {/* Full Length Test */}
                        <button
                            onClick={() => handleQuizSelect('full')}
                            className="p-6 rounded-lg border-2 border-green-200 dark:border-green-800 hover:border-green-300 dark:hover:border-green-700 transition-all hover:shadow-md"
                        >
                            <h3 className="text-lg font-semibold mb-2 text-gray-800 dark:text-gray-100">
                                Full Length Test
                            </h3>
                            <p className="text-sm text-gray-600 dark:text-gray-400">
                                170 questions, timed practice exam simulating real test conditions
                            </p>
                        </button>

                        {/* Quick Quiz */}
                        <button
                            onClick={() => handleQuizSelect('sample')}
                            className="p-6 rounded-lg border-2 border-purple-200 dark:border-purple-800 hover:border-purple-300 dark:hover:border-purple-700 transition-all hover:shadow-md"
                        >
                            <h3 className="text-lg font-semibold mb-2 text-gray-800 dark:text-gray-100">
                                Quick Quiz
                            </h3>
                            <p className="text-sm text-gray-600 dark:text-gray-400">
                                30 questions from mixed categories for rapid practice
                            </p>
                        </button>
                    </div>
                </div>

                {showConfirmation && (
                    <QuizConfirmationModal
                        quizType={selectedQuiz!}
                        onConfirm={handleConfirm}
                        onClose={() => setShowConfirmation(false)}
                    />
                )}
            </>
        );
    };

    const handleUpdateExplanation = async (questionId: number, explanation: string) => {
        try {
            await updateWrongAnswerExplanation(questionId, explanation);
            logAnalyticsEvent(ANALYTICS_EVENTS.FLASHCARD_EXPLANATION_UPDATED, {
                questionId,
                explanationLength: explanation.length,
                isSubscribed: userData?.isSubscribed
            });
            const updatedAnswers = await loadWrongAnswers();
            setWrongAnswers(updatedAnswers);
        } catch (error) {
            console.error('Error updating explanation:', error);
            logAnalyticsEvent(ANALYTICS_EVENTS.ERROR, {
                action: 'update_flashcard_explanation',
                error: error
            });
        }
    };

    const handleMarkComplete = async (questionId: number) => {
        setFlashcardToReview(questionId);
    };

    const handleConfirmReview = async () => {
        if (flashcardToReview) {
            try {
                await removeWrongAnswer(flashcardToReview);
                const updatedAnswers = await loadWrongAnswers();
                setWrongAnswers(updatedAnswers);
            } catch (error) {
                console.error('Error marking as complete:', error);
            }
        }
        setFlashcardToReview(null);
    };

    const handleDateSelect = async (date: Date) => {
        try {
            const updatedProfile = {
                ...(userData?.profile || {
                    name: '',
                    studyGoal: '',
                    onboardingCompleted: false
                }),
                examDate: date.toISOString()
            };

            await updateUserProfile(updatedProfile);
            
            logAnalyticsEvent(ANALYTICS_EVENTS.EXAM_DATE_UPDATED, {
                oldDate: userData?.profile?.examDate,
                newDate: date.toISOString(),
                daysUntilExam: calculateDaysUntilExam(date.toISOString()),
                isSubscribed: userData?.isSubscribed
            });

            if (userData) {
                setUserData({
                    ...userData,
                    profile: updatedProfile
                } as User);
            }
            
            setShowDatePicker(false);
        } catch (error) {
            console.error('Error updating exam date:', error);
            logAnalyticsEvent(ANALYTICS_EVENTS.ERROR, {
                action: 'update_exam_date',
                error: error
            });
        }
    };

    const formatExamDate = (dateString: string | undefined | null) => {
        if (!dateString) return null;
        try {
            const date = new Date(dateString);
            if (isNaN(date.getTime())) return null; // Invalid date
            return format(date, 'MMMM d, yyyy');
        } catch {
            return null;
        }
    };

    const calculateDaysUntilExam = (dateString: string | undefined | null) => {
        if (!dateString) return null;
        try {
            const examDate = new Date(dateString);
            if (isNaN(examDate.getTime())) return null; // Invalid date
            return Math.ceil((examDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
        } catch {
            return null;
        }
    };

    const handleShare = (platform: 'x' | 'facebook' | 'linkedin', text: string) => {
        logAnalyticsEvent(ANALYTICS_EVENTS.SHARE_RESULT, {
            platform,
            latestScore: latestResult?.score,
            totalQuestions: latestResult?.totalQuestions,
            isSubscribed: userData?.isSubscribed
        });
        shareToSocial(platform, text);
    };

    return (
        <div className="new-home-container">
            <div className="new-home-content">
                <header className="mb-8">
                    <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100">
                        Hi, {userData?.profile?.name || 'Future ASWB Graduate'}. Welcome Back to your ASWB Test Prep!
                    </h1>
                </header>
                <div className="new-home-inner">
                    {/* Top Row - Fixed Height Cards */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
                        {/* Exam Countdown - Add max-height */}
                        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6 border border-gray-200 dark:border-gray-600 h-[200px]">
                            <div className="flex justify-between items-start mb-4">
                                <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">Exam Countdown</h2>
                                {userData?.profile?.examDate && (
                                    <button
                                        onClick={() => setShowDatePicker(true)}
                                        className="text-sm text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
                                    >
                                        Change Date
                                    </button>
                                )}
                            </div>
                            {userData?.profile?.examDate && formatExamDate(userData.profile.examDate) ? (
                                <>
                                    <p className="text-3xl font-bold text-blue-600 dark:text-blue-400">
                                        {calculateDaysUntilExam(userData.profile.examDate)} days
                                    </p>
                                    <p className="text-sm text-gray-600 dark:text-gray-400 mt-2">
                                        Exam date: {formatExamDate(userData.profile.examDate)}
                                    </p>
                                </>
                            ) : (
                                <div className="text-center py-4">
                                    <p className="text-3xl font-bold text-gray-500 dark:text-gray-400 mb-4">Not scheduled</p>
                                    <button
                                        onClick={() => setShowDatePicker(true)}
                                        className="inline-flex items-center px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors"
                                    >
                                        <CalendarIcon className="w-4 h-4 mr-2" />
                                        Set Exam Date
                                    </button>
                                </div>
                            )}
                        </div>

                        {/* Latest Score - Add max-height */}
                        <div className="h-[200px]">
                            <TestScores testResults={userData?.testResults ?? []} />
                        </div>

                        {/* Quiz Actions - Add max-height */}
                        <div className="h-[200px]">
                            <QuizActions isSubscribed={userData?.isSubscribed ?? false} />
                        </div>
                    </div>

                    {/* Study Options Section */}
                    <div className="mb-6">
                        <QuizTypes />
                    </div>

                    {/* Adaptive Insights - Full Width */}
                    <div className="mb-6">
                        <AdaptiveInsightsCard adaptiveLearning={userData?.adaptiveLearning} />
                    </div>

                    {/* Flashcards Section - Full Width */}
                    <div className="mb-6">
                        <FlashCards 
                            wrongAnswers={userData?.totalWrongAnswers || []}
                            onUpdateExplanation={handleUpdateExplanation}
                            onMarkComplete={handleMarkComplete}
                        />
                    </div>

                    {/* Bottom Row - Social Sharing */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6 border border-gray-200 dark:border-gray-600">
                            <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200 flex items-center">
                                <Share2 className="mr-2" /> Share Your Progress
                            </h2>
                            <p className="text-gray-600 dark:text-gray-400 mb-4">
                                {latestResult 
                                    ? `Share your ${latestResult.score}/${latestResult.totalQuestions} score!`
                                    : 'Take a quiz to share your results!'}
                            </p>
                            <div className="flex space-x-2">
                                <button 
                                    onClick={() => handleShare('x', buildShareText(latestResult))}
                                    className="bg-black hover:bg-gray-900 text-white p-2 rounded-full transition-colors"
                                    disabled={!latestResult}
                                >
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                                    </svg>
                                </button>
                                <button 
                                    onClick={() => handleShare('facebook', buildShareText(latestResult))}
                                    className="bg-blue-600 hover:bg-blue-700 text-white p-2 rounded-full transition-colors"
                                    disabled={!latestResult}
                                >
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                        <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
                                    </svg>
                                </button>
                                <button 
                                    onClick={() => handleShare('linkedin', buildShareText(latestResult))}
                                    className="bg-blue-700 hover:bg-blue-800 text-white p-2 rounded-full transition-colors"
                                    disabled={!latestResult}
                                >
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showOnboarding && (
                <OnboardingModal onClose={() => setShowOnboarding(false)} />
            )}

            {showDatePicker && (
                <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
                    <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-md w-full">
                        <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">
                            Select Exam Date
                        </h3>
                        <input
                            type="date"
                            min={new Date().toISOString().split('T')[0]}
                            onChange={(e) => handleDateSelect(new Date(e.target.value))}
                            className="w-full p-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-gray-100"
                        />
                        <button
                            onClick={() => setShowDatePicker(false)}
                            className="mt-4 w-full px-4 py-2 bg-gray-200 dark:bg-gray-700 rounded-lg"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dashboard;
