import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className='new-home-container'>
        <div className="min-h-screen flex flex-col items-center justify-center text-center p-4">
            <h1 className="text-4xl font-bold mb-4 text-gray-900 dark:text-gray-100">404 - Page Not Found</h1>
            <p className="mb-8 text-gray-700 dark:text-gray-300">Oops! The page you&apos;re looking for doesn&apos;t exist.</p>
            <Link to="/" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            Go back to Home
            </Link>
        </div>
    </div>
  );
};

export default NotFound;