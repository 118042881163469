import React from 'react';
import { Helmet } from 'react-helmet';

const Disclosures: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Disclosures | Social Work Test Prep Academy</title>
        <meta name="description" content="Read our disclosure statement for important information about our ASWB LCSW exam preparation services, content disclaimer, and user responsibilities." />
        <meta property="og:title" content="Disclosures | Social Work Test Prep Academy" />
        <meta property="og:description" content="Important disclosures about our ASWB LCSW exam preparation services and user responsibilities." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="new-home-container">
        <div className="new-home-content">
          <div className="new-home-inner">
            <div className="disclosures">
              <h1 className="text-2xl sm:text-3xl font-bold mb-4 text-center text-gray-800 dark:text-gray-200">Disclosure Statement for Social Work Test Prep Academy</h1>
              <p className="text-base sm:text-lg mb-6 text-center text-gray-600 dark:text-gray-300">Last updated: Thursday September 19th 2024</p>

              <h2 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">1. Purpose of Service</h2>
              <p className="mb-4 text-gray-700 dark:text-gray-300">
                Social Work Test Prep Academy provides test preparation materials and services for the Association of Social Work Boards (ASWB) Licensed Clinical Social Worker (LCSW) examination. Our content is designed to assist users in their exam preparation but does not guarantee success on the actual ASWB LCSW exam.
              </p>

              <h2 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">2. Content Disclaimer</h2>
              <p className="mb-4 text-gray-700 dark:text-gray-300">
                The content provided through our service is for educational and preparatory purposes only. While we strive for accuracy and relevance, we do not guarantee that our materials cover all possible questions or topics that may appear on the official ASWB LCSW exam.
              </p>

              <h2 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">3. Not Affiliated with ASWB</h2>
              <p className="mb-4 text-gray-700 dark:text-gray-300">
                Social Work Test Prep Academy is not affiliated with, endorsed by, or connected to the Association of Social Work Boards (ASWB) or any official licensing body. Our service is an independent resource for exam preparation.
              </p>

              <h2 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">4. Expert Contributors</h2>
              <p className="mb-4 text-gray-700 dark:text-gray-300">
                Our quiz questions and materials are curated by professionals with extensive experience in the field of social work. However, individual contributors&apos; opinions may not represent official exam content or standards.
              </p>

              <h2 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">5. User Responsibility</h2>
              <p className="mb-4 text-gray-700 dark:text-gray-300">
                Users are responsible for verifying the licensing requirements in their specific jurisdiction and ensuring they meet all necessary qualifications to sit for the ASWB LCSW exam.
              </p>

              <h2 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">6. No Guarantee of Results</h2>
              <p className="mb-4 text-gray-700 dark:text-gray-300">
                While we believe our services can significantly aid in exam preparation, we cannot and do not guarantee that use of our materials will result in passing the ASWB LCSW exam.
              </p>

              <h2 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">7. Beta Version Disclosure</h2>
              <p className="mb-4 text-gray-700 dark:text-gray-300">
                Our current offering is in beta testing phase. Users should be aware that features, content, and functionality may change as we continue to develop and refine our service based on user feedback and testing results.
              </p>

              <h2 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">8. Data Usage</h2>
              <p className="mb-4 text-gray-700 dark:text-gray-300">
                User quiz results and progress data are collected and used to improve our service and provide personalized feedback. Please refer to our Privacy Policy for more detailed information on data collection and usage.
              </p>

              <h2 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">9. Third-Party Links</h2>
              <p className="mb-4 text-gray-700 dark:text-gray-300">
                Our service may contain links to third-party websites or resources. We are not responsible for the content or practices of these external sites.
              </p>

              <h2 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">10. Intellectual Property</h2>
              <p className="mb-4 text-gray-700 dark:text-gray-300">
                All content provided through Social Work Test Prep Academy, including but not limited to quiz questions, explanations, and study materials, is protected by copyright and other intellectual property laws. Reproduction or distribution without explicit permission is prohibited.
              </p>

              <h2 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">11. Changes to Service</h2>
              <p className="mb-4 text-gray-700 dark:text-gray-300">
                We reserve the right to modify, suspend, or discontinue any part of our service at any time without notice.
              </p>

              <h2 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">12. Contact Information</h2>
              <p className="mb-4 text-gray-700 dark:text-gray-300">
                If you have any questions about this disclosure statement or our services, please contact us at: support@socialworktestprepacademy.com
              </p>

              <p className="mt-6 text-gray-700 dark:text-gray-300">
                By using Social Work Test Prep Academy, you acknowledge that you have read, understood,
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Disclosures;
