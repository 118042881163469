import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { logAnalyticsEvent, ANALYTICS_EVENTS } from './utils/Analytics';

interface FAQItemProps {
  question: string;
  answer: string;
}

export function BuyButtonComponent() {
  const [buyUrl, setBuyUrl] = useState('');
  const [user, setUser] = useState<User | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        // Fetch the user's subscription status
        const userDoc = await getDoc(doc(getFirestore(), 'users', user.uid));
        const userData = userDoc.data();
        setSubscriptionStatus(userData?.subscriptionStatus || null);

        if (userData?.subscriptionStatus !== 'active') {
          const stripePaymentLink = process.env.NODE_ENV === 'production' 
            ? process.env.REACT_APP_STRIPE_PAYMENT_LINK_KEY 
            : process.env.REACT_APP_STRIPE_PAYMENT_LINK_KEY_DEV;
          const baseUrl = `https://buy.stripe.com/${stripePaymentLink}`;
          setBuyUrl(baseUrl);
        }
      }
    });
    return () => unsubscribe();
  }, []);

  const handleSubscribeClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    logAnalyticsEvent(ANALYTICS_EVENTS.SUBSCRIPTION_CLICKED, {
      subscriptionStatus,
      isLoggedIn: !!user
    });
    
    if (!user) {
      e.preventDefault();
      setShowModal(true);
    }
  };

  const handleSignIn = () => {
    navigate('/signin?from=/pricing');
    setShowModal(false);
  };

  if (subscriptionStatus === 'active') {
    return (
      <div className="text-center">
        <p className="text-green-600 dark:text-green-400 font-bold mb-2">
          You have an active subscription!
        </p>
        <button
          onClick={() => navigate('/dashboard')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Go to Your Dashboard
        </button>
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      <a
        href={buyUrl + '?client_reference_id=' + user?.uid }
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleSubscribeClick}
      >
        Subscribe Now
      </a>
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4 text-gray-900 dark:text-white">Sign In Required</h2>
            <p className="mb-4 text-gray-700 dark:text-gray-300">Please sign in to subscribe to our service.</p>
            <div className="flex justify-end">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                onClick={handleSignIn}
              >
                Sign In
              </button>
              <button
                className="bg-gray-300 hover:bg-gray-400 dark:bg-gray-600 dark:hover:bg-gray-700 text-black dark:text-white font-bold py-2 px-4 rounded"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const PricingPage: React.FC = () => {

  useEffect(() => {
    logAnalyticsEvent(ANALYTICS_EVENTS.PRICING_PAGE_VIEWED);
  }, []);

  return (
    <div className="new-home-container bg-white dark:bg-gray-900 text-gray-900 dark:text-white min-h-screen">
      <div className="new-home-content max-w-4xl mx-auto px-4 py-8">
      <div className="new-home-inner">

        <h1 className="text-3xl sm:text-4xl font-bold mb-2 text-gray-900 dark:text-white text-center">
          Ace Your Social Work Exam with Confidence
        </h1>
        <h2 className="text-2xl sm:text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200 text-center">
          Full Exam Access
        </h2>
        <p className="text-4xl font-bold mb-4 text-gray-900 dark:text-white text-center">
          $15.99/month
        </p>
        <p className="text-lg mb-6 text-gray-700 dark:text-gray-300 text-center">
          Get access to the entire 170 question exam
        </p>
        <div className="flex justify-center mb-8">
        {/* <div>
          <script async src="https://js.stripe.com/v3/buy-button.js"></script>
          <stripe-buy-button
          buy-button-id="buy_btn_1Q6kMcGiU2k5hJRTUvg0YQef"
          publishable-key={getStripeKey()}
          client-reference-id={getAuth().currentUser?.uid || ''}
          customer-email={getAuth().currentUser?.email || ''}
          >
          </stripe-buy-button>
        </div> */}
          <BuyButtonComponent />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
          <BenefitItem icon="📚" text="170+ Real Exam-Style Questions" />
          <BenefitItem icon="🧠" text="Detailed Explanations for Every Answer" />
          <BenefitItem icon="📊" text="Track Your Progress with Performance Analytics" />
          <BenefitItem icon="📱" text="Study on Any Device, Anytime" />
        </div>
        <HighlyRecommended />
        <MoneyBackGuarantee />
        <FAQ />
        <Disclaimer />
      </div>
    </div>
    </div>
    // </div>
    // </div>
  );
};

const BenefitItem: React.FC<{ icon: string; text: string }> = ({ icon, text }) => (
  <div className="flex items-center space-x-2 text-gray-800 dark:text-gray-200 bg-gray-100 dark:bg-gray-800 p-3 rounded-lg">
    <span className="text-2xl">{icon}</span>
    <span>{text}</span>
  </div>
);

const HighlyRecommended: React.FC = () => (
  <div className="bg-gray-200 dark:bg-gray-700 p-6 rounded-lg mb-8 text-center">
    <div className="flex justify-center items-center mb-3">
      {[...Array(5)].map((_, i) => (
        <svg key={i} className="w-6 h-6 text-yellow-400 fill-current" viewBox="0 0 24 24">
          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
        </svg>
      ))}
    </div>
    <p className="text-xl font-bold text-gray-900 dark:text-white mb-2">Highly Recommended</p>
  </div>
);

const MoneyBackGuarantee: React.FC = () => (
  <div className="text-center mb-8">
    <p className="text-gray-900 dark:text-white font-bold">7-Day Money-Back Guarantee</p>
    <p className="text-gray-700 dark:text-gray-300">Not satisfied? Get a full refund, no questions asked.</p>
  </div>
);

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4">
      <button
        className="flex justify-between items-center w-full text-left font-semibold text-gray-900 dark:text-white bg-gray-200 dark:bg-gray-700 p-4 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{question}</span>
        <span className="text-xl">{isOpen ? '−' : '+'}</span>
      </button>
      {isOpen && (
        <div className="mt-2 p-4 bg-gray-100 dark:bg-gray-800 rounded-lg text-gray-700 dark:text-gray-300">
          {answer}
        </div>
      )}
    </div>
  );
};

const Disclaimer: React.FC = () => (
  <div className="text-sm text-gray-600 mt-8 p-4 border border-gray-700 rounded-lg">
    <h4 className="font-semibold mb-2">Disclaimer:</h4>
    <p>
    At Social Work Test Prep Academy, we believe in fair pricing that promotes equity. Our goal is to make high-quality social work test prep accessible through fair and flexible pricing, ensuring that all aspiring social workers can achieve their goals without the burden of cost. Your success should be based on your dedication, not your wallet, and we are committed to supporting you on your journey.
    </p>
  </div>
);

const FAQ: React.FC = () => {
  const faqData = [
    {
      question: "How long do I have access to the 170-question test after purchase?",
      answer: "You'll have unlimited access to the 170-question test for as long as your subscripton is active."
    },
    {
      question: "Can I take the test more than once?",
      answer: "Absolutely! You can take the test as many times as you'd like as long as you're subscribed monthly. We encourage multiple attempts to help reinforce your learning and track your progress."
    },
    {
      question: "Is this test an exact replica of the actual ASWB LCSW exam?",
      answer: "While our test closely simulates the ASWB LCSW exam in terms of content areas and difficulty level, it's not an exact replica. Our questions are crafted by LCSW experts to prepare you thoroughly for the types of questions you'll encounter on the actual exam."
    },
    {
      question: "How does your test compare to other LCSW exam prep materials?",
      answer: "Our test stands out due to its accuracy, comprehensive explanations, and adaptive learning technology. We continuously update our question bank based on the latest ASWB guidelines and user feedback."
    },
    {
      question: "Will I get explanations for incorrect answers?",
      answer: "Yes! Each question comes with detailed explanations for both correct and incorrect answers. This helps you understand the reasoning behind each option and reinforces your learning."
    },
    {
      question: "Is there a mobile app available for studying on the go?",
      answer: "Currently, our platform is fully responsive and works great on mobile browsers. We're actively developing a dedicated mobile app to enhance your on-the-go study experience. Stay tuned for its release!"
    },
    {
      question: "What if I'm not satisfied with the test? Is there a refund policy?",
      answer: "We offer a 7-day money-back guarantee. If you're not completely satisfied with our test, contact our support team within 7 days of purchase for a full refund, no questions asked."
    },
    {
      question: "How often is the test content updated?",
      answer: "We review and update our test content quarterly to ensure it aligns with the latest ASWB exam guidelines. Any major changes to the LCSW exam are reflected in our test as soon as possible."
    },
    {
      question: "Can I pause the test and resume later?",
      answer: "Yes, you can pause the test at any time and resume where you left off. Your progress is automatically saved, allowing for flexible study sessions."
    },
    {
      question: "Do you offer any study materials besides the 170-question test?",
      answer: "While the 170-question test is our core offering, we're constantly expanding our study materials. Currently, we provide detailed explanations, a performance analytics dashboard, and targeted review suggestions based on your test results."
    },
    // {
    //   question: "How does your adaptive learning technology work?",
    //   answer: "Our adaptive learning algorithm analyzes your performance on each question and adjusts the difficulty of subsequent questions accordingly. This ensures you're always challenged at the right level and focuses your study on areas that need improvement."
    // },
    {
      question: "Is there a way to track my progress over time?",
      answer: "Absolutely! Our performance analytics dashboard allows you to track your scores, identify trends in your performance, and see which content areas need more focus. This feature helps you study more efficiently and effectively."
    },
    {
      question: "Can I use this test as my only study material for the LCSW exam?",
      answer: "While our test is comprehensive, we recommend using it in conjunction with other study materials for the best results. It's an excellent tool for practice and identifying knowledge gaps, but a well-rounded study approach typically yields the best outcomes."
    },
    // {
    //   question: "Do you offer any group discounts for study groups or institutions?",
    //   answer: "Yes, we offer discounted rates for groups of 5 or more. Please contact our sales team at sales@socialworktestprepacademy.com for more information on group pricing."
    // },
    {
      question: "How does your test prepare me for the time management aspect of the LCSW exam?",
      answer: "Our test closely mimics the time constraints of the actual LCSW exam. You'll have the same amount of time per question as you would in the real exam, helping you practice your pacing and time management skills."
    }
  ];

  return (
    <div className="text-white mb-8">
      <h3 className="font-bold text-2xl mb-6 text-center">Frequently Asked Questions</h3>
      {faqData.map((item, index) => (
        <FAQItem key={index} question={item.question} answer={item.answer} />
      ))}
    </div>
  );
};

export default PricingPage;
