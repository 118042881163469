import { db, auth } from '../firebase';
import { doc, setDoc, getDoc, updateDoc, collection, query, where, getDocs, documentId, deleteField, arrayUnion, limit, QueryConstraint } from 'firebase/firestore';
import { QuizProgress, TestResult, WrongAnswer, UserProfile, User } from '../models/User';
import { Question } from '../models/Quiz';
import { sanitizeQuestion, shuffleArray } from './quizUtils';
import { onSnapshot } from 'firebase/firestore';
import typedQuestionsData from './questions.json';


const validateQuestions = (questions: Question[]): Question[] => {
  const uniqueQuestions = questions.filter((q, index, self) => 
    self.findIndex(sq => sq.id === q.id) === index
  );
  return uniqueQuestions;
};

export const fetchQuestions = async (
  isSubscribed: boolean, 
  questionIds?: number[],
  category?: string,
  type: 'sample' | 'full' | 'category' = 'sample'
): Promise<Question[]> => {
  console.log('fetchQuestions called with:', { isSubscribed, questionIds, category, type });
  try {
    // For sample quizzes or non-subscribed users, use local questions
    if (type === 'sample' || !isSubscribed) {
      console.log('Using local questions');
      const allLocalQuestions = typedQuestionsData.map(sanitizeQuestion);
      const shuffledQuestions = shuffleArray(allLocalQuestions);
      return validateQuestions(shuffledQuestions);
    } 

    // Create the base query
    console.log('Fetching from Firestore');
    const questionsRef = collection(db, 'questions');
    let queryConstraints: QueryConstraint[] = [];

    // Add constraints based on type
    switch (type) {
      case 'category':
        if (!category) throw new Error('Category required for category quiz');
        queryConstraints = [
          where('category', '==', category),
          limit(30)
        ];
        break;
      case 'full':
        if (!isSubscribed) throw new Error('Full quiz requires subscription');
        queryConstraints = [limit(100)];
        break;
      default:
        queryConstraints = [limit(15)];
    }

    // Create and execute the query
    const q = query(questionsRef, ...queryConstraints);
    const querySnapshot = await getDocs(q);
    
    if (querySnapshot.empty) {
      console.warn('No questions found for query');
      return [];
    }

    const questions = querySnapshot.docs.map(doc => 
      sanitizeQuestion({
        ...doc.data() as Question,
        id: doc.id
      })
    );
    
    return validateQuestions(questions);
  } catch (error) {
    console.error('Error in fetchQuestions:', error);
    throw error;
  }
};

export const migrateQuizData = async (data: any): Promise<{ [key: string]: QuizProgress }> => {
  // Check subscription status
  const isSubscribed = data?.subscriptionStatus === 'active';

  // If there's a legacy single quiz in progress
  if (data?.quizProgress) {
    const legacyQuiz = data.quizProgress;
    const quizType = determineQuizType(legacyQuiz, isSubscribed);
    
    return {
      [legacyQuiz.quizId]: {
        ...legacyQuiz,
        type: quizType,
        questionIds: legacyQuiz.questionIds || legacyQuiz.questions.map((q: { id: any; }) => q.id)
      }
    };
  }
  
  // If there's already a quizzes map structure, validate types
  if (data?.quizzes) {
    const updatedQuizzes = Object.entries(data.quizzes).reduce((acc, [id, quiz]: [string, any]) => {
      const quizType = determineQuizType(quiz, isSubscribed);
      acc[id] = {
        ...quiz,
        type: quizType
      };
      return acc;
    }, {} as { [key: string]: QuizProgress });
    
    return updatedQuizzes;
  }
  
  return {};
};

const determineQuizType = (quiz: any, isSubscribed: boolean): 'sample' | 'full' | 'category' => {
  // If quiz already has a valid type, keep it
  if (quiz.type && ['sample', 'full', 'category'].includes(quiz.type)) {
    return quiz.type;
  }

  // If it has a category, it's a category quiz
  if (quiz.category) {
    return 'category';
  }

  // Check question count to determine if it's a full or sample quiz
  const questionCount = quiz.questionIds?.length || quiz.questions?.length || 0;
  
  // If user is subscribed and has more than 30 questions, it's likely a full quiz
  if (isSubscribed && questionCount > 30) {
    return 'full';
  }

  // Default to sample quiz
  return 'sample';
};

export const getAllQuizzes = async (): Promise<{ [key: string]: QuizProgress }> => {
  // Wait for auth state to be initialized
  await new Promise(resolve => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    });
  });

  const user = auth.currentUser;
  if (!user) {
    console.error('No authenticated user found in getAllQuizzes');
    throw new Error('Authentication required');
  }

  try {
    console.log('Fetching quizzes for user:', user.uid); // Debug log
    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);
    
    if (!userDoc.exists()) {
      console.log('No user document found, returning empty quizzes');
      return {};
    }

    const data = userDoc.data();
    console.log('User data retrieved:', data ? 'exists' : 'null'); // Debug log
    
    // Migrate data if needed
    const quizzes = await migrateQuizData(data);
    
    // If we had to migrate, save the new structure
    if (data?.quizProgress && !data?.quizzes) {
      try {
        await updateDoc(userDocRef, {
          quizzes,
          quizProgress: deleteField()
        });
      } catch (migrationError) {
        console.error('Error during migration:', migrationError);
        // Continue even if migration fails
      }
    }

    return quizzes;
  } catch (error) {
    console.error('Error in getAllQuizzes:', error);
    if (error instanceof Error) {
      throw new Error(`Failed to load quizzes: ${error.message}`);
    }
    throw new Error('Failed to load quizzes');
  }
};

export const saveQuizProgress = async (quizId: number, progress: QuizProgress): Promise<void> => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  try {
    const userDocRef = doc(db, 'users', user.uid);
    const sanitizedProgress = {
      ...progress,
      type: progress.type || 'sample',
      questionIds: progress.questionIds || progress.questions.map(q => q.id)
    };

    // Get existing wrong answers
    const userDoc = await getDoc(userDocRef);
    const userData = userDoc.data();
    const existingWrongAnswers = userData?.wrongAnswers || [];

    // Add new wrong answers (avoiding duplicates)
    const newWrongAnswers = progress.wrongAnswers.filter(newWA => 
      !existingWrongAnswers.some((existingWA: { question: { id: number; }; }) => 
        existingWA.question.id === newWA.question.id
      )
    );

    const updatedWrongAnswers = [...existingWrongAnswers, ...newWrongAnswers];

    // Update both quiz progress and wrong answers
    await updateDoc(userDocRef, { 
      [`quizzes.${quizId}`]: sanitizedProgress,
      wrongAnswers: updatedWrongAnswers,
      quizProgress: deleteField()
    });
  } catch (error) {
    console.error('Error saving quiz progress:', error);
    throw new Error('Failed to save quiz progress');
  }
};

export const loadQuizProgress = async (quizId: string): Promise<QuizProgress | null> => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const userDocRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userDocRef);
  const data = userDoc.data();

  // Check new quizzes structure first
  if (data?.quizzes?.[quizId]) {
    const progress = data.quizzes[quizId] as QuizProgress;
    if (!progress.questionIds) {
      progress.questionIds = progress.questions.map(q => q.id);
    }
    return progress;
  }

  // Fallback to legacy quizProgress if exists
  if (data?.quizProgress) {
    const progress = data.quizProgress as QuizProgress;
    if (!progress.questionIds) {
      progress.questionIds = progress.questions.map(q => q.id);
    }
    return progress;
  }

  return null;
};

export const saveTestResult = async (result: TestResult): Promise<void> => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  // Add validation to prevent incomplete test saves
  if (!isTestComplete(result)) {
    console.warn('Attempted to save incomplete test result');
    return;
  }

  try {
    const lightweightResult = {
      ...result,
      questions: result.questions.map(q => q.id),
      wrongAnswers: result.wrongAnswers.map(wa => wa.question.id),
      completedAt: new Date().toISOString()  // Add completion timestamp
    };

    const userDocRef = doc(db, 'users', user.uid);
    await updateDoc(userDocRef, {
      testResults: arrayUnion(lightweightResult)
    });
  } catch (error) {
    console.error('Error saving test result:', error);
    throw new Error('Failed to save test result');
  }
};

// Add validation helper
const isTestComplete = (result: TestResult): boolean => {
  return (
    result.questions.length > 0 &&
    result.questions.every(q => q.selected_answer !== null) &&
    result.score !== undefined &&
    result.date !== undefined
  );
};

export const loadTestResults = async (): Promise<TestResult[]> => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const userDocRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userDocRef);
  const data = userDoc.data();
  return data?.testResults || [];
};

export const createUserInFirestore = async (uid: string, email: string) => {
  const userRef = doc(db, 'users', uid);
  const initialProfile: UserProfile = {
    name: '',
    examDate: '',
    studyGoal: '',
    onboardingCompleted: false
  };
  await setDoc(userRef, {
    email: email,
    profile: initialProfile,
    createdAt: new Date(),
  });
};

export const loadUserData = async (): Promise<User | null> => {
  try {
      const user = auth.currentUser;
      if (!user) return null;

      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (!userDoc.exists()) return null;

      const userData = userDoc.data();
      return new User({
          id: user.uid,
          email: user.email || '',
          profile: userData.profile || null,
          quizProgress: userData.quizProgress || null,
          testResults: userData.testResults || [],
          subscriptionStatus: userData.subscriptionStatus || '',
          adaptiveLearning: userData.adaptiveLearning
      });
  } catch (error) {
      console.error('Error loading user data:', error);
      return null;
  }
};

export const updateUserProfile = async (profile: UserProfile): Promise<void> => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const userDocRef = doc(db, 'users', user.uid);
  try {
    await updateDoc(userDocRef, {
      profile: profile
    });
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw new Error('Failed to update user profile');
  }
};

export const loadUserProfile = async (): Promise<UserProfile | null> => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const userDocRef = doc(db, 'users', user.uid);
  try {
    const userDoc = await getDoc(userDocRef);
    const data = userDoc.data();
    return data?.profile || null;
  } catch (error) {
    console.error('Error loading user profile:', error);
    throw new Error('Failed to load user profile');
  }
};

export const checkSubscriptionStatus = async (): Promise<boolean> => {
  const user = auth.currentUser;
  if (!user) {
    console.log('No authenticated user in checkSubscriptionStatus');
    return false;
  }

  const userDocRef = doc(db, 'users', user.uid);
  try {
    console.log('Fetching user document for:', user.uid);
    const userDoc = await getDoc(userDocRef);
    const userData = userDoc.data();
    
    if (!userData) {
      console.warn('User document not found for:', user.uid);
      return false;
    }
    console.log('User data:', userData);
    const isSubscribed = userData.subscriptionStatus === 'active';
    console.log('Is user subscribed:', isSubscribed);
    return isSubscribed;
  } catch (error) {
    console.error('Error checking subscription status:', error);
    return false;
  }
}

export const setupUserStatusListener = (
  userId: string, 
  onUpdate: (isSubscribed: boolean, testInProgress: string | null) => void
) => {
  const userDocRef = doc(db, 'users', userId);
  
  return onSnapshot(userDocRef, async (docSnapshot) => {
    if (docSnapshot.exists()) {
      const userData = docSnapshot.data();
      const isSubscribed = userData.subscriptionStatus === 'active';
      
      // Check for in-progress test
      const testInProgress = await checkSubscribedTestInProgress();
      
      onUpdate(isSubscribed, testInProgress);
    } else {
      onUpdate(false, null);
    }
  }, (error) => {
    console.error("Error listening to user status:", error);
  });
};

export const loadWrongAnswers = async (): Promise<WrongAnswer[]> => {
    const user = auth.currentUser;
    if (!user) throw new Error('No authenticated user');

    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);
    const data = userDoc.data();
    
    // Get test results
    const testResults = data?.testResults || [];
    
    // Collect all wrong answers from test results
    const allWrongAnswers = testResults.reduce((acc: WrongAnswer[], test: TestResult) => {
        return [...acc, ...test.wrongAnswers];
    }, []);

    // Remove duplicates and filter out reviewed items
    const uniqueWrongAnswers = allWrongAnswers.reduce((acc: WrongAnswer[], current: WrongAnswer) => {
        if (!current.isReviewed && !acc.some(wa => wa.question.id === current.question.id)) {
            acc.push(current);
        }
        return acc;
    }, []);

    return uniqueWrongAnswers;
};

export const checkSubscribedTestInProgress = async (): Promise<string | null> => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const testsRef = collection(db, 'subscribedTests');
  const q = query(
    testsRef,
    where('userId', '==', user.uid),
    where('status', '==', 'in-progress')
  );

  try {
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      // Return the ID of the first in-progress test found
      return querySnapshot.docs[0].id;
    }
    return null;
  } catch (error) {
    console.error('Error checking for subscribed test in progress:', error);
    throw new Error('Failed to check for subscribed test in progress');
  }
};

export const updateWrongAnswerExplanation = async (questionId: number, explanation: string): Promise<void> => {
    const user = auth.currentUser;
    if (!user) throw new Error('No authenticated user');

    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);
    const data = userDoc.data();
    
    if (data?.wrongAnswers) {
        const updatedWrongAnswers = data.wrongAnswers.map((answer: WrongAnswer) =>
            answer.question.id === questionId 
                ? { ...answer, explanation } 
                : answer
        );
        
        await updateDoc(userDocRef, {
            wrongAnswers: updatedWrongAnswers
        });
    }
};

export const removeWrongAnswer = async (questionId: number): Promise<void> => {
    const user = auth.currentUser;
    if (!user) throw new Error('No authenticated user');

    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);
    const data = userDoc.data();
    
    if (data?.testResults) {
        // Update wrong answers in all test results
        const updatedTestResults = data.testResults.map((test: TestResult) => ({
            ...test,
            wrongAnswers: test.wrongAnswers.map(answer => 
                answer.question.id === questionId 
                    ? { ...answer, isReviewed: true }
                    : answer
            )
        }));
        
        // Update the document with the modified test results
        await updateDoc(userDocRef, {
            testResults: updatedTestResults
        });
    }
};

export const fetchCategories = async (): Promise<string[]> => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  try {
    const questionsRef = collection(db, 'questions');
    const snapshot = await getDocs(questionsRef);
    
    // Get unique categories from questions
    const categories = new Set<string>();
    snapshot.docs.forEach(doc => {
      const data = doc.data();
      if (data.category) categories.add(data.category);
    });

    return Array.from(categories).sort();
  } catch (error) {
    console.error('Error fetching categories:', error);
    return [];
  }
};

export const loadInProgressQuizzes = async (): Promise<QuizProgress[]> => {
  if (!auth.currentUser) return [];
  
  try {
    const userDocRef = doc(db, 'users', auth.currentUser.uid);
    const userDoc = await getDoc(userDocRef);
    const data = userDoc.data();
    
    if (!data?.quizzes) return [];
    
    const quizzesArray = Object.values(data.quizzes) as QuizProgress[];
    return quizzesArray.filter((quiz) => 
      quiz.currentQuestionIndex < quiz.questions.length
    );
  } catch (error) {
    console.error('Error loading in-progress quizzes:', error);
    return [];
  }
};
