import React from 'react';
import { TestResult } from '../models/User';

interface ScoreGraphProps {
  testScores: TestResult[];
  examDate?: string;  // Make examDate optional
}

export const ScoreGraph: React.FC<ScoreGraphProps> = ({ testScores, examDate }) => {
  const width = 800;
  const height = 400;
  const mobileHeight = 600; // Increased height for mobile
  const padding = { top: 50, right: 50, bottom: 80, left: 80 };
  const passingPercentage = 70;

  if (testScores.length === 0) {
    return (
      <div className="w-full overflow-x-auto">
        <svg width="100%" height={height} viewBox={`0 0 ${width} ${height}`} className="bg-white dark:bg-gray-800 shadow-md rounded-lg">
          <text x="50%" y="50%" textAnchor="middle" fill="currentColor" className="text-gray-500 dark:text-gray-400">
            No test scores available
          </text>
        </svg>
      </div>
    );
  }

  const allDates = [...testScores.map(score => new Date(score.date)), new Date(examDate)];
  const minDate = new Date(Math.min(...allDates.map(d => d.getTime())));
  const maxDate = new Date(examDate);

  const xScale = (index: number) => {
    return (index / (allDates.length - 1)) * (width - padding.left - padding.right) + padding.left;
  };

  const yScale = (percentage: number) => height - padding.bottom - (percentage / 100) * (height - padding.top - padding.bottom);

  const points = testScores.map((score, index) => `${xScale(index)},${yScale(score.percentage)}`).join(' ');

  const formatDate = (date: Date) => date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

  const averageScore = Math.round(testScores.reduce((sum, score) => sum + score.percentage, 0) / testScores.length);
  const isPassingAverage = averageScore >= passingPercentage;

  // Add exam date line only if it's provided
  if (examDate) {
    const examDateLine = xScale(allDates.length - 1);
    const examDateY = yScale(averageScore);
    const examDateTextX = examDateLine + 10;
    const examDateTextY = examDateY - 15;

    return (
      <div className="w-full">
        <div className="overflow-x-auto">
          <svg 
            width="100%" 
            height="100%" 
            viewBox={`0 0 ${width} ${height}`} 
            className="bg-white dark:bg-gray-800 shadow-md rounded-lg md:h-[650px] h-[300px] font-lexend"
            preserveAspectRatio="xMidYMid meet"
          >
            {/* Grid lines */}
            {[0, 25, 50, 75, 100].map(percentage => (
              <line
                key={percentage}
                x1={padding.left}
                y1={yScale(percentage)}
                x2={width - padding.right}
                y2={yScale(percentage)}
                stroke="currentColor"
                strokeOpacity="0.1"
                strokeWidth="1"
                className="text-gray-300 dark:text-gray-600"
              />
            ))}
            
            {/* Passing score line */}
            <line
              x1={padding.left}
              y1={yScale(passingPercentage)}
              x2={width - padding.right}
              y2={yScale(passingPercentage)}
              stroke="#10B981"
              strokeWidth="2"
              strokeDasharray="5,5"
            />
            <text 
              x={width - padding.right + 10} 
              y={yScale(passingPercentage)} 
              fill="#10B981" 
              dominantBaseline="middle" 
              fontSize="16"
              className="hidden md:block"
            >
              90%
            </text>

            {/* Data line */}
            <polyline
              fill="none"
              stroke="#4299e1"
              strokeWidth="3"
              points={points}
            />
            
            {/* Data points */}
            {testScores.map((score, index) => (
              <g key={index}>
                <circle
                  cx={xScale(index)}
                  cy={yScale(score.percentage)}
                  r="6"
                  fill="white"
                  stroke="#4299e1"
                  strokeWidth="3"
                />
                <text
                  x={xScale(index)}
                  y={yScale(score.percentage) - 15}
                  textAnchor="middle"
                  fill="#4299e1"
                  fontSize="16"
                  className="font-semibold"
                >
                  {Math.round(score.percentage)}%
                </text>
              </g>
            ))}
            
            {/* Exam date point */}
            <circle
              cx={xScale(allDates.length - 1)}
              cy={yScale(averageScore)}
              r="8"
              fill={isPassingAverage ? "#10B981" : "#EF4444"}
            />
            <text
              x={xScale(allDates.length - 1)}
              y={yScale(averageScore) - 15}
              textAnchor="middle"
              fill={isPassingAverage ? "#10B981" : "#EF4444"}
              fontSize="16"
              className="font-semibold"
            >
              Exam: {averageScore}%
            </text>
            
            {/* Y-axis */}
            <line x1={padding.left} y1={padding.top} x2={padding.left} y2={height - padding.bottom} stroke="currentColor" className="text-gray-400 dark:text-gray-500" strokeWidth="2" />
            {[0, 50, 100].map(percentage => (
              <text 
                key={percentage} 
                x={padding.left - 15} 
                y={yScale(percentage)} 
                textAnchor="end" 
                fill="currentColor" 
                fontSize="16" 
                className="text-gray-600 dark:text-gray-400"
              >
                {percentage}%
              </text>
            ))}
            
            {/* X-axis */}
            <line x1={padding.left} y1={height - padding.bottom} x2={width - padding.right} y2={height - padding.bottom} stroke="currentColor" className="text-gray-400 dark:text-gray-500" strokeWidth="2" />
            {allDates.map((date, index) => (
              <text
                key={index}
                x={xScale(index)}
                y={height - padding.bottom + 25}
                textAnchor="middle"
                fill="currentColor"
                fontSize="14"
                transform={`rotate(-45 ${xScale(index)} ${height - padding.bottom + 25})`}
                className="text-gray-600 dark:text-gray-400 hidden md:block"
              >
                {formatDate(date)}
              </text>
            ))}
            {/* Show only first and last date on mobile */}
            <text
              x={xScale(0)}
              y={height - padding.bottom + 25}
              textAnchor="start"
              fill="currentColor"
              fontSize="14"
              className="text-gray-600 dark:text-gray-400 md:hidden"
            >
              {formatDate(allDates[0])}
            </text>
            <text
              x={xScale(allDates.length - 1)}
              y={height - padding.bottom + 25}
              textAnchor="end"
              fill="currentColor"
              fontSize="14"
              className="text-gray-600 dark:text-gray-400 md:hidden"
            >
              {formatDate(allDates[allDates.length - 1])}
            </text>
            
            {/* Exam date line */}
            <line
              x1={padding.left}
              y1={yScale(averageScore)}
              x2={width - padding.right}
              y2={yScale(averageScore)}
              stroke="#10B981"
              strokeWidth="2"
              strokeDasharray="5,5"
            />
            <text
              x={width - padding.right + 10}
              y={yScale(averageScore)}
              fill="#10B981"
              dominantBaseline="middle"
              fontSize="16"
              className="hidden md:block"
            >
              {averageScore}%
            </text>
          </svg>
        </div>
        <div className="mt-4 text-center font-lexend">
          <p className="text-lg text-gray-800 dark:text-gray-200">
            Based on your average quiz score of{' '}
            <span className={`font-semibold ${isPassingAverage ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
              {averageScore}%
            </span>
            , you are{' '}
            <span className={`font-semibold ${isPassingAverage ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
              {isPassingAverage ? 'ready' : 'not yet ready'}
            </span>
            {' '}for your exam on{' '}
            <span className="font-semibold">
              {formatDate(new Date(examDate))}
            </span>.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="overflow-x-auto">
        <svg 
          width="100%" 
          height="100%" 
          viewBox={`0 0 ${width} ${height}`} 
          className="bg-white dark:bg-gray-800 shadow-md rounded-lg md:h-[650px] h-[300px] font-lexend"
          preserveAspectRatio="xMidYMid meet"
        >
          {/* Grid lines */}
          {[0, 25, 50, 75, 100].map(percentage => (
            <line
              key={percentage}
              x1={padding.left}
              y1={yScale(percentage)}
              x2={width - padding.right}
              y2={yScale(percentage)}
              stroke="currentColor"
              strokeOpacity="0.1"
              strokeWidth="1"
              className="text-gray-300 dark:text-gray-600"
            />
          ))}
          
          {/* Passing score line */}
          <line
            x1={padding.left}
            y1={yScale(passingPercentage)}
            x2={width - padding.right}
            y2={yScale(passingPercentage)}
            stroke="#10B981"
            strokeWidth="2"
            strokeDasharray="5,5"
          />
          <text 
            x={width - padding.right + 10} 
            y={yScale(passingPercentage)} 
            fill="#10B981" 
            dominantBaseline="middle" 
            fontSize="16"
            className="hidden md:block"
          >
            90%
          </text>

          {/* Data line */}
          <polyline
            fill="none"
            stroke="#4299e1"
            strokeWidth="3"
            points={points}
          />
          
          {/* Data points */}
          {testScores.map((score, index) => (
            <g key={index}>
              <circle
                cx={xScale(index)}
                cy={yScale(score.percentage)}
                r="6"
                fill="white"
                stroke="#4299e1"
                strokeWidth="3"
              />
              <text
                x={xScale(index)}
                y={yScale(score.percentage) - 15}
                textAnchor="middle"
                fill="#4299e1"
                fontSize="16"
                className="font-semibold"
              >
                {Math.round(score.percentage)}%
              </text>
            </g>
          ))}
          
          {/* Exam date point */}
          <circle
            cx={xScale(allDates.length - 1)}
            cy={yScale(averageScore)}
            r="8"
            fill={isPassingAverage ? "#10B981" : "#EF4444"}
          />
          <text
            x={xScale(allDates.length - 1)}
            y={yScale(averageScore) - 15}
            textAnchor="middle"
            fill={isPassingAverage ? "#10B981" : "#EF4444"}
            fontSize="16"
            className="font-semibold"
          >
            Exam: {averageScore}%
          </text>
          
          {/* Y-axis */}
          <line x1={padding.left} y1={padding.top} x2={padding.left} y2={height - padding.bottom} stroke="currentColor" className="text-gray-400 dark:text-gray-500" strokeWidth="2" />
          {[0, 50, 100].map(percentage => (
            <text 
              key={percentage} 
              x={padding.left - 15} 
              y={yScale(percentage)} 
              textAnchor="end" 
              fill="currentColor" 
              fontSize="16" 
              className="text-gray-600 dark:text-gray-400"
            >
              {percentage}%
            </text>
          ))}
          
          {/* X-axis */}
          <line x1={padding.left} y1={height - padding.bottom} x2={width - padding.right} y2={height - padding.bottom} stroke="currentColor" className="text-gray-400 dark:text-gray-500" strokeWidth="2" />
          {allDates.map((date, index) => (
            <text
              key={index}
              x={xScale(index)}
              y={height - padding.bottom + 25}
              textAnchor="middle"
              fill="currentColor"
              fontSize="14"
              transform={`rotate(-45 ${xScale(index)} ${height - padding.bottom + 25})`}
              className="text-gray-600 dark:text-gray-400 hidden md:block"
            >
              {formatDate(date)}
            </text>
          ))}
          {/* Show only first and last date on mobile */}
          <text
            x={xScale(0)}
            y={height - padding.bottom + 25}
            textAnchor="start"
            fill="currentColor"
            fontSize="14"
            className="text-gray-600 dark:text-gray-400 md:hidden"
          >
            {formatDate(allDates[0])}
          </text>
          <text
            x={xScale(allDates.length - 1)}
            y={height - padding.bottom + 25}
            textAnchor="end"
            fill="currentColor"
            fontSize="14"
            className="text-gray-600 dark:text-gray-400 md:hidden"
          >
            {formatDate(allDates[allDates.length - 1])}
          </text>
        </svg>
      </div>
      <div className="mt-4 text-center font-lexend">
        <p className="text-lg text-gray-800 dark:text-gray-200">
          Based on your average quiz score of{' '}
          <span className={`font-semibold ${isPassingAverage ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
            {averageScore}%
          </span>
          , you are{' '}
          <span className={`font-semibold ${isPassingAverage ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
            {isPassingAverage ? 'ready' : 'not yet ready'}
          </span>
          {' '}for your exam on{' '}
          <span className="font-semibold">
            {formatDate(new Date(examDate))}
          </span>.
        </p>
      </div>
    </div>
  );
};