import { TestResult } from '../models/User';

const SITE_URL = process.env.REACT_APP_SITE_URL || 'https://www.socialworktestprepacademy.com';

export const buildShareText = (result: TestResult | null): string => {
    if (!result) return '';
    
    const score = Math.round((result.score / result.totalQuestions) * 100);
    return `I scored ${score}% on my ASWB practice exam! 🎉 Join me in preparing for the LCSW exam at ${SITE_URL}`;
};

export const shareToSocial = (platform: 'x' | 'facebook' | 'linkedin', text: string) => {
    const encodedText = encodeURIComponent(text);
    const urls = {
        x: `https://x.com/intent/tweet?text=${encodedText}`,
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${SITE_URL}&quote=${encodedText}`,
        linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${SITE_URL}&title=ASWB%20Exam%20Prep&summary=${encodedText}`
    };

    // Open in a new window with specific dimensions
    const width = 550;
    const height = 400;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;
    
    window.open(
        urls[platform],
        'share',
        `width=${width},height=${height},left=${left},top=${top}`
    );
}; 