import { LucideIcon } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from './card';

interface DashboardCardProps {
  icon: LucideIcon;
  title: string;
  children: React.ReactNode;
}

export const DashboardCard = ({ icon: Icon, title, children }: DashboardCardProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Icon className="w-5 h-5" />
          {title}
        </CardTitle>
      </CardHeader>
      <CardContent>
        {children}
      </CardContent>
    </Card>
  );
}; 