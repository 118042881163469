import { logAnalyticsEvent } from './Analytics';

export const trackPageLoad = () => {
  if (window.performance) {
    const observer = new PerformanceObserver((list) => {
      const entries = list.getEntries();
      entries.forEach(entry => {
        if (entry.entryType === 'navigation') {
          const navEntry = entry as PerformanceNavigationTiming;
          const pageLoadTime = navEntry.loadEventEnd - navEntry.startTime;
          
          logAnalyticsEvent('page_load_time', {
            timeInMs: pageLoadTime,
            url: window.location.pathname,
            type: navEntry.type // 'navigate', 'reload', 'back_forward', 'prerender'
          });
        }
      });
    });

    observer.observe({ entryTypes: ['navigation'] });
  }
};

export const trackScrollDepth = () => {
  let maxScroll = 0;
  
  window.addEventListener('scroll', () => {
    const scrollPercent = Math.round(
      (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100
    );
    
    if (scrollPercent > maxScroll) {
      maxScroll = scrollPercent;
      if (maxScroll % 25 === 0) { // Track at 25%, 50%, 75%, 100%
        logAnalyticsEvent('scroll_depth', {
          depth: maxScroll,
          url: window.location.pathname
        });
      }
    }
  });
}; 