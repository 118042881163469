import React from 'react';
import { XIcon } from 'lucide-react';
import Onboarding from '../Onboarding';

interface OnboardingModalProps {
  onClose: () => void;
}

const OnboardingModal: React.FC<OnboardingModalProps> = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-black/50 dark:bg-black/70 flex items-center justify-center p-4 z-50 overflow-y-auto">
      <div className="bg-white dark:bg-gray-800 rounded-lg max-w-2xl w-full shadow-xl relative">
        <button 
          onClick={onClose} 
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          <XIcon className="h-6 w-6" />
        </button>
        <Onboarding isModal={true} onComplete={onClose} />
      </div>
    </div>
  );
};

export default OnboardingModal; 