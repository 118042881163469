import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from './firebase';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import LoadingSpinner from './components/LoadingSpinner';
import { createUserInFirestore } from './utils/FirebaseUtils';

const FinishSignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');

  // Extract the 'from' parameter from the URL
  const from = new URLSearchParams(location.search).get('from') || '/Onboarding';

  useEffect(() => {
    // Get email from location state
    const emailFromState = location.state?.email;
    if (emailFromState) {
      setEmail(emailFromState);
    }
  }, [location]);

  const handleCreateAccount = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    setStatus('loading');
    setError(null);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(userCredential.user);
      await createUserInFirestore(userCredential.user.uid, email);
      setStatus('success');
      setTimeout(() => navigate(from), 3000);
    } catch (error) {
      setStatus('error');
      if (error instanceof Error) {
        if (error.message.includes('email-already-in-use')) {
          setError('This email is already in use. Please sign in instead.');
        } else {
          setError('An error occurred during account creation. Please try again.');
        }
      }
    }
  };

  const renderContent = () => {
    switch (status) {
      case 'loading':
        return <LoadingSpinner />;
      case 'success':
        return (
          <div className="text-center">
            <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-gray-200">Account Created Successfully!</h2>
            <p className="text-lg mb-8 text-center text-gray-600 dark:text-gray-300400">
              {from === '/pricing' 
                ? "Redirecting you to the pricing page..." 
                : "We had a few questions and after we will direct you to your free quiz!"}
            </p>
          </div>
        );
      case 'error':
      case 'idle':
      default:
        return (
          <form onSubmit={handleCreateAccount} className="space-y-4">
            {error && (
              <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded dark:bg-red-900 dark:border-red-700 dark:text-red-100" role="alert">
                {error}
              </div>
            )}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300"
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300"
              />
              <p className="mt-2 text-xs text-gray-500 dark:text-gray-400">
                Password must be at least 6 characters long. For better security, consider using a mix of letters, numbers, and symbols.
              </p>
            </div>
            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Confirm Password</label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300"
              />
            </div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-600"
            >
              Create Your Profile
            </button>
          </form>
        );
    }
  };

  return (
    <div className="new-home-container">
      <div className="new-home-content">
        <h2 className="text-2xl font-bold mb-4 text-center text-gray-800 dark:text-gray-200">
          {from === '/pricing' 
            ? "Sign Up to Subscribe to your AWSB LCSW 170 question test prep" 
            : "Sign Up Now to Access Your Free AWSB LCSW Quiz!"}
        </h2>
        <div className="new-home-inner">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default FinishSignIn;
