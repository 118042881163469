import useUserPreference from './useUserPreference';

function useDarkMode() {
  const [isDarkMode, setIsDarkMode] = useUserPreference<boolean>('darkMode', false, (value) => {
    document.documentElement.classList.toggle('dark', value);
  });

  const toggleDarkMode = () => setIsDarkMode(prev => !prev);

  return { isDarkMode, toggleDarkMode };
}

export default useDarkMode;