import React from 'react';
import { AdaptiveLearningProfile } from '../../models/User';
import { Brain, TrendingUp, Clock, Target } from 'lucide-react';
import { DashboardCard } from '../ui/dashboard-card';

interface AdaptiveInsightsCardProps {
  adaptiveLearning?: AdaptiveLearningProfile;
}

const AdaptiveInsightsCard: React.FC<AdaptiveInsightsCardProps> = ({ adaptiveLearning }) => {
  if (!adaptiveLearning) return null;

  const { quickMetrics, insights } = adaptiveLearning;

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      {/* Overall Performance Card */}
      <DashboardCard icon={Brain} title="Learning Progress">
        <div className="grid grid-cols-2 gap-4">
          <div className="text-center">
            <p className="text-3xl font-bold text-blue-600 dark:text-blue-400">
              {quickMetrics.overallSuccessRate.toFixed(1)}%
            </p>
            <p className="text-sm text-gray-600 dark:text-gray-400">Success Rate</p>
          </div>
          <div className="text-center">
            <p className="text-3xl font-bold text-green-600 dark:text-green-400">
              {quickMetrics.estimatedExamReadiness}%
            </p>
            <p className="text-sm text-gray-600 dark:text-gray-400">Exam Readiness</p>
          </div>
        </div>
        
        {/* Mastery Breakdown */}
        <div className="mt-4">
          <h4 className="font-semibold mb-2">Category Mastery</h4>
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <span>Advanced</span>
              <span className="text-green-500">{quickMetrics.mastery.advanced.length}</span>
            </div>
            <div className="flex justify-between items-center">
              <span>Intermediate</span>
              <span className="text-yellow-500">{quickMetrics.mastery.intermediate.length}</span>
            </div>
            <div className="flex justify-between items-center">
              <span>Beginner</span>
              <span className="text-red-500">{quickMetrics.mastery.beginner.length}</span>
            </div>
          </div>
        </div>
      </DashboardCard>

      {/* Recent Activity Card */}
      <DashboardCard icon={TrendingUp} title="Recent Performance">
        <div className="space-y-4">
          {quickMetrics.recentPerformance.slice(0, 3).map((perf, idx) => (
            <div key={idx} className="flex justify-between items-center">
              <div>
                <p className="font-semibold">{perf.category}</p>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  {new Date(perf.date).toLocaleDateString()}
                </p>
              </div>
              <div className="text-right">
                <p className="font-bold text-blue-600 dark:text-blue-400">
                  {perf.score.toFixed(1)}%
                </p>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  {perf.questionsAttempted} questions
                </p>
              </div>
            </div>
          ))}
        </div>
      </DashboardCard>

      {/* Study Habits Card */}
      <DashboardCard icon={Clock} title="Study Patterns">
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <span>Weekly Streak</span>
            <span className="font-bold">{quickMetrics.weeklyActivityStreak} weeks</span>
          </div>
          <div className="flex justify-between items-center">
            <span>Study Time This Week</span>
            <span className="font-bold">{(quickMetrics.studyTimeThisWeek / 3600).toFixed(1)} hours</span>
          </div>
          <div className="flex justify-between items-center">
            <span>Current Streak</span>
            <span className="font-bold text-green-500">{quickMetrics.currentStreak} days</span>
          </div>
        </div>
      </DashboardCard>

      {/* Focus Areas Card */}
      <DashboardCard icon={Target} title="Recommended Focus">
        <div className="space-y-4">
          <div>
            <h4 className="font-semibold mb-2">Weak Categories</h4>
            <ul className="list-disc list-inside text-sm">
              {insights.weakCategories.slice(0, 3).map((category, idx) => (
                <li key={idx} className="text-red-500">{category}</li>
              ))}
            </ul>
          </div>
          <div>
            <h4 className="font-semibold mb-2">Concepts to Review</h4>
            <ul className="list-disc list-inside text-sm">
              {insights.conceptsToReview.slice(0, 3).map((concept, idx) => (
                <li key={idx} className="text-yellow-500">{concept}</li>
              ))}
            </ul>
          </div>
        </div>
      </DashboardCard>
    </div>
  );
};

export default AdaptiveInsightsCard; 