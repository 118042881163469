import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Question } from '../models/Quiz';
import { AdaptiveLearningEngine } from '../utils/adaptiveLearning';
import LoadingSpinner from './LoadingSpinner';
// Import mock data for testing
import { mockUser, mockWrongAnswers, availableQuestions as allQuestions } from '../utils/mockTestData';
import AdaptiveInsights from '../utils/AdaptiveLearningCard';
import QuizSummary from './QuizSummary';

interface AdaptiveQuizProps {
  quizId: number;
  isSubscribed: boolean;
  category?: string;
}

const AdaptiveQuiz: React.FC<AdaptiveQuizProps> = ({ quizId, isSubscribed, category }) => {
  // Core state management
  const [currentQuestion, setCurrentQuestion] = useState<Question | null>(null);
  const [questionNumber, setQuestionNumber] = useState(1);
  const [timeSpent, setTimeSpent] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  
  // Performance tracking
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [questionStartTime, setQuestionStartTime] = useState<Date | null>(null);
  
  // Adaptive engine state
  const [engine, setEngine] = useState<AdaptiveLearningEngine | null>(null);

  // Add new state
  const [isComplete, setIsComplete] = useState(false);

  // Initialize engine with detailed logging
  useEffect(() => {
    const initializeEngine = async () => {
      console.group('🚀 Initializing Adaptive Engine');
      console.log('📊 Initial User Profile:', mockUser.profile);
      console.log('📝 Previous Test Results:', mockUser.testResults);
      console.log('❌ Wrong Answer History:', mockWrongAnswers);
      
      setIsLoading(true);
      try {
        const engine = new AdaptiveLearningEngine(
          mockUser.profile!,
          mockUser.testResults,
          mockWrongAnswers
        );
        
        setEngine(engine);
        setStartTime(new Date());
        console.log('✅ Engine Successfully Initialized');
      } catch (error) {
        console.error('❌ Engine Initialization Failed:', error);
      } finally {
        setIsLoading(false);
      }
      console.groupEnd();
    };

    initializeEngine();
  }, []);

  // Simplify to use all questions without category filtering
  const availableQuestions = useMemo(() => {
    return allQuestions;
  }, []); // No dependencies needed since we want all questions

  // Load next question with logging
  const loadNextQuestion = useCallback(async () => {
    if (!engine || !availableQuestions.length) return;

    console.group('🎯 Loading Next Question');
    console.log('📚 Available Questions:', availableQuestions.length);
    
    setIsLoading(true);
    try {
      const nextQuestion = engine.selectNextQuestion(availableQuestions);
      console.log('➡️ Selected Question:', {
        id: nextQuestion.id,
        category: nextQuestion.category,
        difficulty: engine.getCurrentDifficulty()
      });
      
      setCurrentQuestion(nextQuestion);
      setQuestionStartTime(new Date());
    } finally {
      setIsLoading(false);
    }
    console.groupEnd();
  }, [engine, availableQuestions]);

  // Handle answer with detailed logging
  const handleAnswer = useCallback(async (selectedAnswer: string) => {
    if (!currentQuestion || !engine || !questionStartTime) return;

    console.group('📝 Processing Answer');
    
    // Calculate time spent
    const timeSpent = (new Date().getTime() - questionStartTime.getTime()) / 1000;
    console.log('⏱️ Time Spent:', timeSpent.toFixed(2), 'seconds');

    // Check if answer is correct
    const isCorrect = selectedAnswer === currentQuestion.correct_answer;
    console.log('✅ Correct Answer:', currentQuestion.correct_answer);
    console.log('👉 Selected Answer:', selectedAnswer);
    console.log(isCorrect ? '🎉 Correct!' : '❌ Incorrect');

    // Update engine with result
    console.group('🔄 Updating Engine');
    console.log('Before Update:', {
      difficulty: engine.getCurrentDifficulty(),
      consecutiveCorrect: engine.getConsecutiveCorrect(),
      categoryStrengths: engine.getCategoryStrengths()
    });

    engine.updateWithResult(
      currentQuestion,
      isCorrect,
      timeSpent
    );

    console.log('After Update:', {
      difficulty: engine.getCurrentDifficulty(),
      consecutiveCorrect: engine.getConsecutiveCorrect(),
      categoryStrengths: engine.getCategoryStrengths()
    });
    console.groupEnd();

    // Generate insights every 10 questions
    if (questionNumber % 10 === 0) {
      console.group('📊 Generating Insights');
      const insights = engine.generateInsights();
      console.log('Learning Insights:', insights);
      console.groupEnd();
    }

    // Check if we've gone through all questions
    if (questionNumber >= availableQuestions.length) {
      setIsComplete(true);
    } else {
      setQuestionNumber(prev => prev + 1);
      await loadNextQuestion();
    }
  }, [currentQuestion, engine, questionStartTime, questionNumber, availableQuestions.length, loadNextQuestion]);

  // Add reset function
  const handleRestart = useCallback(() => {
    setIsComplete(false);
    setQuestionNumber(1);
    setTimeSpent(0);
    setCurrentQuestion(null);
    setStartTime(new Date());
    loadNextQuestion();
  }, [loadNextQuestion]);

  // Initial question load
  useEffect(() => {
    if (engine && !currentQuestion) {
      loadNextQuestion();
    }
  }, [engine, currentQuestion, loadNextQuestion]);

  // Add this helper function to format scores for the chart
  const formatRecentScores = useCallback(() => {
    return mockUser.testResults.map(test => ({
      date: new Date(test.date).toLocaleDateString(),
      score: test.percentage
    }));
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isComplete && engine) {
    return (
      <QuizSummary
        engine={engine}
        totalQuestions={availableQuestions.length}
        timeSpent={timeSpent}
        onRestartQuiz={handleRestart}
      />
    );
  }

  return (
    <div className="max-w-6xl mx-auto p-4">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Quiz Section */}
        <div className="space-y-4">
          {currentQuestion && (
            <div className="space-y-4">
              {/* Debug/Stats Panel */}
              <div className="mb-4 p-4 bg-gray-100 dark:bg-gray-800 rounded-lg text-sm">
                <h3 className="font-semibold mb-2">Adaptive Engine Stats:</h3>
                <div className="space-y-1">
                  <p>Difficulty Level: {engine?.getCurrentDifficulty()}/10</p>
                  <p>Consecutive Correct: {engine?.getConsecutiveCorrect()}</p>
                  <p>Category Performance:</p>
                  <ul className="pl-4">
                    {engine?.getCategoryStrengths().slice(0, 3).map(cat => (
                      <li key={cat.category}>
                        {cat.category}: {(cat.successRate * 100).toFixed(1)}% 
                        ({cat.totalAttempts} attempts)
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              {/* Question display */}
              <div className="question-container">
                <h2 className="text-xl font-semibold mb-4">
                  Question {questionNumber}
                </h2>
                <p className="mb-4">{currentQuestion.question_text}</p>
              </div>

              {/* Answer options */}
              <div className="answer-options space-y-2">
                {['A', 'B', 'C', 'D'].map(option => (
                  <button
                    key={option}
                    onClick={() => handleAnswer(option)}
                    className="w-full p-3 text-left rounded-lg border hover:bg-gray-100 
                             dark:hover:bg-gray-700 transition-colors"
                  >
                    {option}. {currentQuestion[`option_${option.toLowerCase()}` as keyof Question]}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Insights Section */}
        {engine && (
          <div className="lg:sticky lg:top-4 space-y-4">
            <AdaptiveInsights 
              engine={engine}
              recentScores={formatRecentScores()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AdaptiveQuiz; 