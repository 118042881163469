import { useState, useEffect } from 'react';

type PreferenceType = boolean | string | number;

function useUserPreference<T extends PreferenceType>(
  key: string,
  defaultValue: T,
  onChange?: (value: T) => void
) {
  const [preference, setPreference] = useState<T>(() => {
    const savedValue = localStorage.getItem(key);
    if (savedValue !== null) {
      return JSON.parse(savedValue) as T;
    }
    if (typeof defaultValue === 'boolean' && key === 'darkMode') {
      return window.matchMedia('(prefers-color-scheme: dark)').matches as T;
    }
    return defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(preference));
    if (onChange) {
      onChange(preference);
    }
  }, [key, preference, onChange]);

  const setPreferenceValue = (value: T | ((prev: T) => T)) => {
    setPreference(value);
  };

  return [preference, setPreferenceValue] as const;
}

export default useUserPreference;