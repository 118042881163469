import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import QuizContainer from './components/QuizContainer';
import { initializeQuizForUser, loadQuizProgress } from './utils/quizUtils';
import { checkSubscriptionStatus } from './utils/FirebaseUtils'
import { QuizProgress } from './models/User';
import { logAnalyticsEvent } from './utils/Analytics';

const QuizApp: React.FC = () => {
  const { quizId: paramQuizId } = useParams();
  const [quizProgress, setQuizProgress] = useState<QuizProgress | null>(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const initQuiz = async () => {
      setIsLoading(true);
      try {
        const subscriptionStatus = await checkSubscriptionStatus();
        setIsSubscribed(subscriptionStatus);

        let progress: QuizProgress | null = null;
        
        if (paramQuizId) {
          // Try to load existing quiz
          progress = await loadQuizProgress(Number(paramQuizId), subscriptionStatus);
        }
        
        if (!progress) {
          // Initialize new quiz if no existing quiz found
          const { quizId: newQuizId } = await initializeQuizForUser(subscriptionStatus);
          progress = await loadQuizProgress(newQuizId, subscriptionStatus);
          
          if (!progress) {
            throw new Error('Failed to initialize quiz');
          } else {
            logAnalyticsEvent('quiz_started', {
              quiz_id: Number(paramQuizId),
              quiz_type: progress.type ?? 'sample',
              category: progress.category
            });
          }
        }

        
        setQuizProgress(progress);
      } catch (error) {
        logAnalyticsEvent('error_occurred', {
          error_type: 'initialization_error',
          error_message: error instanceof Error ? error.message : 'Unknown error',
          component: 'QuizApp'
        });
        console.error('Error initializing quiz:', error);
        setError(error instanceof Error ? error.message : 'Failed to initialize quiz');
      } finally {
        setIsLoading(false);
      }
    };

    initQuiz();
  }, [paramQuizId]);

  if (isLoading) {
    return (
      <div className="new-home-container">
      <div className="new-home-content">
        <div className="new-home-inner">
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
      </div>
      </div>
      </div>

      </div>
    );
  }

  if (error || !quizProgress) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <h2 className="text-xl text-red-600 mb-4">
            {error || 'Unable to load quiz'}
          </h2>
          <button 
            onClick={() => window.location.reload()}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="new-home-container flex items-center justify-center px-4 py-8">
      <QuizContainer 
        quizId={quizProgress.quizId} 
        isSubscribed={isSubscribed}
        initialQuizProgress={quizProgress}
      />
    </div>
  );
};

export default QuizApp;