export const PASSING_PERCENTAGE = 70;
export const INITIAL_QUIZ_TIME = 85; // seconds
export const QUIZ_PROGRESS_PREFIX = 'quizProgress_';
export const CURRENT_QUIZ_KEY = 'currentQuiz';
export const TEST_RESULT_KEY = 'testResult';
export const TEST_RESULTS_KEY = 'testResults';
export const WRONG_ANSWERS_KEY = 'wrongAnswers';
export const DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric'
};
export const QUIZ_ID_PREFIX = 'quiz_';
export const INITIAL_QUESTION_INDEX = 0;
export const INITIAL_SCORE = 0;
export const FEEDBACK_EMAIL = 'feedback@example.com';
export const LOADING_MESSAGE = 'Loading...';
export const NO_SAVED_QUIZ_MESSAGE = 'No saved quiz found.';
export const NO_PREVIOUS_QUIZ_MESSAGE = 'No previous quiz found to retake.';
